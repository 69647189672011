import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import Marquee from "react-fast-marquee";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const riskManagementArray = [
    { title: 'CUTTING-EDGE RISK CONTROL' },
    { title: 'REAL-TIME POSITION UPDATES' },
    { title: 'MINIMAL SLIPPAGES FOR STOPS/ENTRY ORDERS' },
    { title: 'TRANSPARENCY THROUGH WEEKLY STATISTICS' },
    { title: 'CONTINUOUS RISK VS. MARKET PRICE AWARENESS' },
    { title: 'SECURE TRADING, EVEN DURING POWER OUTAGES' },
    { title: 'LIGHTNING-FAST FILLS & LOW LATENCY' },
    { title: 'COMPETITIVE INTERBANK SPREADS' },
    { desc: 'At Quantbridge, diversify into 2 or more portfolios to minimize risk and maximize gains. Tailor your risk level to match your goals, time horizon, and tolerance for volatility, potentially improving returns. Trust in Quantbridge for a rewarding investment experience.' }
]

const RiskManagement = (props) => {
    const [imageIndex, setImageIndex] = useState(0);

    const settings = {
        dots: false,
        infinite: true,
        lazyLoad: "ondemand",
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: true,
        speed: 300,
        slidesToShow: 1,
        swipe: false,
        centerMode: true,
        centerPadding: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        beforeChange: (current, next) => setImageIndex(next),
    };

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <img
                    src={require("../../assets/img/ourTeam/riskManagementSliderRight.png").default}
                    className="nextArrow"
                    alt="next"
                />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <img
                    src={require("../../assets/img/ourTeam/riskManagementSliderLeft.png").default}
                    className="prevArrow"
                    alt="prev"
                />
            </div>
        );
    }

    return (
        <>
            <div className="" id="riskManagement">
                <Row className="justify-content-center align-items-start mx-0 position-relative">
                    <Col lg={10} md={11} sm={12} xs={12} className="">
                        <div className="text-center">
                            <span className="sectionTitle d-block text-center">
                                {translate(props.lang, "ESSENTIAL RISK MANAGEMENT")}
                            </span>
                            <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4 d-block mx-auto" />
                        </div>
                    </Col>
                    <Col lg={5} md={11} sm={12} xs={12} className="mb-5">
                        <div className="text-center">
                            <span className="sectionDesc d-block text-center">
                                {translate(props.lang, "At Quantbridge, risk management is paramount. It's a crucial part of our strategy, ensuring success in our trades and safeguarding our investors.")}
                            </span>
                        </div>
                    </Col>
                </Row>

                <Row className="justify-content-center align-items-start mx-0 position-relative mt-5">
                    <Col lg={6} md={12} sm={12} xs={12} className="px-0">
                        <div className="text-left riskManagementSectionBg">
                            <div>
                                <span className="riskManagementSmallTitle d-block text-left mb-4">
                                    {translate(props.lang, "SECURED RISK MANAGEMENT")}
                                </span>
                                <span className="sectionDesc d-block text-left">
                                    {translate(props.lang, "Our dedicated team oversees risk management firm-wide. Emphasizing liquid markets, proprietary risk models, and a diversified funding structure to strengthen our approach. Your investments are safe with us.")}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className="px-0">
                        <div className="text-left riskManagementSliderBg">
                            <Slider {...settings} className="w-100" >
                                {
                                    riskManagementArray.map((item, i) => (
                                        <div className="w-100" key={i}>
                                            <div className="text-left mb-5">
                                                <span className="riskManagementSmallTitle d-block text-left mb-4">
                                                    {translate(props.lang, "ADVANCED RISK CONTROL")}
                                                </span>
                                                <hr className="riskManagementSliderLine" />
                                            </div>
                                            <div className="position-relative text-right mt-5 ">
                                                {item.title ?
                                                    <span className="riskManagementSliderTitle d-block text-right mb-4">
                                                        {translate(props.lang, item.title)}
                                                    </span>
                                                    :
                                                    <span className="sectionDesc d-block text-right mb-4">
                                                        {translate(props.lang, item.desc)}
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>

                            <div className="progressBarSection">
                                <ProgressBar completed={(imageIndex / 8) * 100} isLabelVisible={false} baseBgColor={"#4B6270"} bgColor="#08D1FF" height="0.5vh" />
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className="marqueeSectionBg">
                    <Marquee>
                        <div className="mx-3">
                            <span className="marqueeHugeText d-block">{translate(props.lang, "TRADE WITH CONFIDENCE AND FREEDOM")}</span>
                        </div>
                        <div className="mx-3">
                            <span className="marqueeHugeText d-block">{translate(props.lang, "TRADE WITH CONFIDENCE AND FREEDOM")}</span>
                        </div>
                    </Marquee>
                </div>

            </div>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(RiskManagement);