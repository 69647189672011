import React from "react";
import { Col, Row } from "reactstrap";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const InvestRoadmap = (props) => {

    function hoverMapSection(index) {
        if (window.innerWidth > 991) {
            for (let i = 1; i < 5; i++) {
                var elementId = "stepIndex" + i;
                var elementId2 = "roadmapImg" + i;

                if (i.toString() === index) {
                    document.getElementById(elementId).style.boxShadow = "0px 2px 50px 10px #0EB7FF";
                    document.getElementById(elementId2).style.mixBlendMode = "normal";
                    document.getElementById(elementId2).style.opacity = 1;
                }
                else {
                    document.getElementById(elementId).style.boxShadow = "none";
                    document.getElementById(elementId2).style.mixBlendMode = "luminosity";
                    document.getElementById(elementId2).style.opacity = 0.5;
                }
            }
        }
    }

    function mouseLeaveHover() {
        if (window.innerWidth > 991) {
            for (let i = 1; i < 5; i++) {
                var elementId = "stepIndex" + i;
                var elementId2 = "roadmapImg" + i;

                document.getElementById(elementId).style.boxShadow = "none";
                document.getElementById(elementId2).style.mixBlendMode = "luminosity";
                document.getElementById(elementId2).style.opacity = 0.5;
            }
        }
    }

    return (
        <>
            <div className="" id="investRoadmap">
                <div className="text-center">
                    <span className="sectionTitle d-block text-center">
                        {translate(props.lang, "Set Financial Goals")}
                    </span>
                    <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4 d-block mx-auto" />
                </div>

                <Row className="justify-content-between align-items-center mx-0 investRoadmapMap position-relative">
                    <Col lg={5} md={6} sm={8} xs={8} className="text-right roadmapMargin order-1 position-relative">
                        <img src={require("../../assets/img/howToInvest/step1.png").default} alt="" className="d-block roadmapImg text-right mr-auto mr-md-0 ml-md-auto" id="roadmapImg1" onMouseEnter={() => hoverMapSection("1")} onMouseLeave={() => mouseLeaveHover()} />
                    </Col>
                    <Col lg={5} md={6} sm={12} xs={12} className="text-left roadmapMargin order-2">
                        <div className="stepIndex mb-4 mb-lg-5" id="stepIndex1" onMouseEnter={() => hoverMapSection("1")} onMouseLeave={() => mouseLeaveHover()}>
                            <span>01</span>
                        </div>

                        <span className="stepTitle d-block text-left mb-4">
                            {translate(props.lang, "Create an Account")}
                        </span>
                        <span className="sectionDesc d-block text-left">
                            {translate(props.lang, "Register with QuantBridge and complete verification")}
                        </span>
                    </Col>


                    <Col lg={5} md={6} sm={12} xs={12} className="text-left text-md-right roadmapMargin order-4 order-md-3">
                        <div className="stepIndex mb-4 mb-lg-5 mr-auto mr-md-0 ml-md-auto" id="stepIndex2" onMouseEnter={() => hoverMapSection("2")} onMouseLeave={() => mouseLeaveHover()}>
                            <span>02</span>
                        </div>

                        <span className="stepTitle d-block text-left text-md-right mb-4">
                            {translate(props.lang, "Start Your MT5 Account")}
                        </span>
                        <span className="sectionDesc d-block text-left text-md-right">
                            {translate(props.lang, "Access your MT5 account provided by QuantBridge.")}
                        </span>
                    </Col>
                    <Col lg={5} md={6} sm={8} xs={8} className="text-left roadmapMargin order-3 order-md-4">
                        <img src={require("../../assets/img/howToInvest/step2.png").default} alt="" className="d-block roadmapImg text-left ml-auto ml-md-0 mr-md-auto" id="roadmapImg2" onMouseEnter={() => hoverMapSection("2")} onMouseLeave={() => mouseLeaveHover()} />
                    </Col>

                    <Col lg={5} md={6} sm={8} xs={8} className="text-right roadmapMargin order-5">
                        <img src={require("../../assets/img/howToInvest/step3.png").default} alt="" className="d-block roadmapImg text-right mr-auto mr-md-0 ml-md-auto" id="roadmapImg3" onMouseEnter={() => hoverMapSection("3")} onMouseLeave={() => mouseLeaveHover()} />
                    </Col>
                    <Col lg={5} md={6} sm={12} xs={12} className="text-left roadmapMargin order-6">
                        <div className="stepIndex mb-4 mb-lg-5" id="stepIndex3" onMouseEnter={() => hoverMapSection("3")} onMouseLeave={() => mouseLeaveHover()}>
                            <span>03</span>
                        </div>

                        <span className="stepTitle d-block text-left mb-4">
                            {translate(props.lang, "Copy Trade")}
                        </span>
                        <span className="sectionDesc d-block text-left">
                            {translate(props.lang, "Utilize Copy Trading feature to replicate experienced traders moves.")}
                        </span>
                    </Col>


                    <Col lg={5} md={6} sm={12} xs={12} className="text-left text-md-right roadmapMargin order-8 order-md-7">
                        <div className="stepIndex mb-4 mb-lg-5 mr-auto mr-md-0 ml-md-auto" id="stepIndex4" onMouseEnter={() => hoverMapSection("4")} onMouseLeave={() => mouseLeaveHover()}>
                            <span>04</span>
                        </div>

                        <span className="stepTitle d-block text-left text-md-right mb-4">
                            {translate(props.lang, "Trade Management")}
                        </span>
                        <span className="sectionDesc d-block text-left text-md-right">
                            {translate(props.lang, "Learn trade management tools in MT5, set stop-loss, and adjust sizes.")}
                        </span>
                    </Col>
                    <Col lg={5} md={6} sm={8} xs={8} className="text-left roadmapMargin order-7 order-md-8">
                        <img src={require("../../assets/img/howToInvest/step4.png").default} alt="" className="d-block roadmapImg text-left ml-auto ml-md-0 mr-md-auto" id="roadmapImg4" onMouseEnter={() => hoverMapSection("4")} onMouseLeave={() => mouseLeaveHover()} />
                    </Col>

                    <img src={require("../../assets/img/howToInvest/roadmapLine.png").default} alt="" className="mx-auto roadmapLine d-none d-md-block" />

                </Row>

                <Row className="justify-content-center align-items-center mx-0">
                    <Col lg={8} md={12} sm={12} xs={12} className="text-right">
                        <div className="roadmapLastBox">
                            <span className="sectionDesc d-block text-center">
                                {translate(props.lang, "By following these steps, you can begin your investment journey with confidence, leveraging the power of MT5 and our expert strategies to achieve your financial goals.")}
                            </span>
                        </div>

                        <div className="btnDiv">
                            <a href="https://client.quantbridge.io/register" target="_blank">
                                <div className="learnMoreBtn text-center mx-auto mt-5 d-flex justify-content-center align-items-center">
                                    <span className="">
                                        {translate(props.lang, "Start Now")}
                                    </span>
                                </div>
                            </a>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(InvestRoadmap);