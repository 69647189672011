import React from "react";
import { Col, Input, Row } from "reactstrap";
import countries from './countries';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const countryArray = [
    { value: "Malaysia", label: "Malaysia" },
    { value: "Singapore", label: "Singapore" },
]

const departmentArray = [
    { value: "General Inquiry", label: "General Inquiry" },
    { value: "Human Resources", label: "Human Resources" },
    { value: "Investor Relations", label: "Investor Relations" },
]

const ContactUsForm = (props) => {
    const [formData, setFormData] = React.useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        country: "",
        companyName: "",
        department: "General Inquiry",
        inquiryTools: "",
        message: ""
    });

    let errorsObj = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        country: "",
        companyName: "",
        department: "",
        inquiryTools: "",
        message: ""
    };

    const [errors, setErrors] = React.useState(errorsObj);

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        let fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e.target;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    function submitForm() {
        let check = true;
        let errorMsg = {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            country: "",
            companyName: "",
            inquiryTools: "",
            message: ""
        }

        if (!formData.firstName) {
            errorMsg.firstName = "This field is required.";
            check = false;
        }

        if (!formData.lastName) {
            errorMsg.lastName = "This field is required.";
            check = false;
        }

        if (!formData.email) {
            errorMsg.email = "This field is required.";
            check = false;
        }

        if (!formData.phoneNumber) {
            errorMsg.phoneNumber = "This field is required.";
            check = false;
        }

        if (!formData.country) {
            errorMsg.country = "This field is required.";
            check = false;
        }

        if (!formData.companyName) {
            errorMsg.companyName = "This field is required.";
            check = false;
        }

        if (!formData.department) {
            errorMsg.department = "This field is required.";
            check = false;
        }

        if (!formData.inquiryTools) {
            errorMsg.inquiryTools = "This field is required.";
            check = false;
        }

        if (!formData.message) {
            errorMsg.message = "This field is required.";
            check = false;
        }

        setErrors(errorMsg);

        if (check) {
            window.open("mailto:support@quantbridge.io?subject=Quantbridge Website Contact Form&body=First Name: " + formData.firstName
                + "%0D%0ALast Name: " + formData.lastName + "%0D%0AEmail: " + formData.email +
                "%0D%0APhone No: " + formData.phoneNumber + "%0D%0ACountry: " + formData.country +
                "%0D%0ACompany Name: " + formData.companyName + "%0D%0ADepartment: " + formData.department +
                "%0D%0AInquiry Tools: " + formData.inquiryTools + "%0D%0AMessage: " + formData.message
            );
        }
    }

    return (
        <>
            <Row className="justify-content-center align-items-center mx-0" id="contactUsForm">
                <Col lg={8} md={11} sm={12} xs={12}>
                    <Row className="justify-content-center align-items-start mx-0 contactFormBg">
                        <Col lg={10} md={12} sm={12} xs={12} className="my-5 text-center text-lg-left">
                            <span className="contactFormTitle text-center text-lg-left">{translate(props.lang, "Send Us a message")}</span>
                        </Col>

                        <Col lg={5} md={5} sm={11} xs={11} className="columnSpacing">
                            <span className="contactFormFieldTitle">{translate(props.lang, "Your Name")} *</span>
                            < input className="form-control border-none contactForm-input mt-3 mb-2"
                                type="text"
                                name="firstName"
                                placeholder={
                                    translate(props.lang, "First Name")}
                                value={formData.firstName}
                                onChange={(e) => handleAddFormChange(e)}
                            />
                            <span className="font-weight-400 errorTextRed">{translate(props.lang, errors.firstName)}</span>
                        </Col>

                        <Col lg={5} md={5} sm={11} xs={11} className="columnSpacing pt-2">
                            <input className="form-control border-none contactForm-input mt-4 mb-2"
                                type="text"
                                name="lastName"
                                placeholder={translate(props.lang, "Last Name")}
                                value={formData.lastName}
                                onChange={(e) => handleAddFormChange(e)}
                            />
                            <span className="font-weight-400 errorTextRed">{translate(props.lang, errors.lastName)}</span>
                        </Col>

                        <Col lg={5} md={5} sm={11} xs={11} className="columnSpacing">
                            <span className="contactFormFieldTitle">{translate(props.lang, "Email")} *</span>
                            < input className="form-control border-none contactForm-input mt-3 mb-2"
                                type="text"
                                name="email"
                                placeholder={translate(props.lang, "Email")}
                                value={formData.email}
                                onChange={(e) => handleAddFormChange(e)}
                            />
                            <span className="font-weight-400 errorTextRed">{translate(props.lang, errors.email)}</span>
                        </Col>

                        <Col lg={5} md={5} sm={11} xs={11} className="columnSpacing">
                            <span className="contactFormFieldTitle">{translate(props.lang, "Phone Number")}</span>
                            < input className="form-control border-none contactForm-input mt-3 mb-2"
                                type="text"
                                name="phoneNumber"
                                placeholder={translate(props.lang, "Phone Number")}
                                value={formData.phoneNumber}
                                onChange={(e) => handleAddFormChange(e)}
                            />
                            <span className="font-weight-400 errorTextRed">{translate(props.lang, errors.phoneNumber)}</span>
                        </Col>

                        <Col lg={5} md={5} sm={11} xs={11} className="columnSpacing">
                            <span className="contactFormFieldTitle">{translate(props.lang, "Country")} *</span>
                            < Input
                                type="select"
                                name="country"
                                className="form-control border-none contactForm-input mt-3 mb-2"
                                value={formData.country}
                                onChange={(e) => handleOptionSelected(e, "country")}
                            >
                                <option value="" disabled="disabled">{translate(props.lang, "Please Choose A Country")}</option>
                                {countries && countries.map((item, i) => (
                                    <option value={item.name} key={i}>{item.name}</option>
                                ))}
                            </Input>
                            <span className="font-weight-400 errorTextRed">{translate(props.lang, errors.country)}</span>
                        </Col>

                        <Col lg={5} md={5} sm={11} xs={11} className="columnSpacing">
                            <span className="contactFormFieldTitle">{translate(props.lang, "Company Name")}</span>
                            < input className="form-control border-none contactForm-input mt-3 mb-2"
                                type="text"
                                name="companyName"
                                placeholder={translate(props.lang, "Company Name")}
                                value={formData.companyName}
                                onChange={(e) => handleAddFormChange(e)}
                            />
                            <span className="font-weight-400 errorTextRed">{translate(props.lang, errors.companyName)}</span>
                        </Col>

                        <Col lg={5} md={5} sm={11} xs={11} className="columnSpacing">
                            <span className="contactFormFieldTitle">{translate(props.lang, "Department")} *</span>
                            <Input
                                type="select"
                                name="department"
                                className="form-control border-none contactForm-input mt-3 mb-2"
                                value={formData.department}
                                onChange={(e) => handleOptionSelected(e, "department")}
                            >

                                {/* <option value="" disabled="disabled">Please Choose A Department</option> */}
                                {departmentArray && departmentArray.map((item, i) => (
                                    <option value={item.value} key={i}>{item.label}</option>
                                ))}
                            </Input>
                            <span className="font-weight-400 errorTextRed">{translate(props.lang, errors.department)}</span>
                        </Col>

                        <Col lg={5} md={5} sm={11} xs={11} className="columnSpacing">
                            <span className="contactFormFieldTitle">{translate(props.lang, "Inquiry Tools")}</span>
                            <input className="form-control border-none contactForm-input mt-3 mb-2"
                                type="text"
                                name="inquiryTools"
                                placeholder={translate(props.lang, "Your Subject")}
                                value={formData.inquiryTools}
                                onChange={(e) => handleAddFormChange(e)}
                            />
                            <span className="font-weight-400 errorTextRed">{translate(props.lang, errors.inquiryTools)}</span>
                        </Col>

                        <Col lg={10} md={10} sm={11} xs={11} className="columnSpacing">
                            <span className="contactFormFieldTitle">{translate(props.lang, "Message")}</span>
                            <Input
                                type="textarea"
                                rows="10"
                                name="message"
                                className="yourmessage-input mt-3 contactForm-input mb-2"
                                placeholder={translate(props.lang, "Your Message")}
                                value={formData.message}
                                onChange={(e) => handleAddFormChange(e)}
                            />
                            <span className="font-weight-400 errorTextRed">{translate(props.lang, errors.message)}</span>
                        </Col>

                        <Col lg={10} md={12} sm={11} xs={11} className="columnSpacing">
                            <div className="submitBtn text-left mt-5 mx-auto justify-content-center align-items-center" onClick={() => submitForm()}>
                                <span className="">
                                    {translate(props.lang, "Send")}
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(ContactUsForm);