import React from "react";
import { Col, Row } from "reactstrap";
import Zoom from "react-reveal/Zoom";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const Oversight = (props) => {

    return (
        <>
            <Zoom duration={1500}>
                <Row className="justify-content-center align-items-start mx-0" id="oversight">
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-5">
                        <div className="text-center">
                            <span className="sectionSmallTitle">
                                {translate(props.lang, "Safe and Transparent Trading with")}
                            </span>
                        </div>
                        <div className="text-center">
                            <span className="sectionTitle">
                                {translate(props.lang, "Dual Regulatory Oversight")}
                            </span>
                        </div>

                        <div className="text-center">
                            <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="mt-3" />
                        </div>
                    </Col>

                    <Col lg={5} md={10} sm={12} xs={12}>
                        <div className="oversightDiv">
                            <span className="oversightTitle d-block mb-4">
                                {translate(props.lang, "Bost Technology Limited: Pioneering the Way as a Premier and Innovative Trading Platform")}
                            </span>

                            <span className="sectionDesc d-block mb-4 text-justify">
                                {translate(props.lang, "Bost Technology Limited is a trailblazing trading platform, dedicated to delivering an exceptional trading environment for clients. With an unwavering commitment to providing top-tier trading experiences, Bost Technology Limited continuously elevates its technology and platform, equipping traders with advanced tools to navigate market fluctuations adeptly. This proactive approach amplifies the prospects of trading success.")}
                            </span>

                            <div className="oversightImgPos">
                                <img src={require("../../assets/img/our-partner/oversight1.png").default} alt="" className="" />
                            </div>
                        </div>
                    </Col>

                    <Col lg={5} md={10} sm={12} xs={12}>
                        <div className="oversightDiv">
                            <span className="oversightTitle d-block mb-5">
                                {translate(props.lang, "Your Secure and Reliable Trading Partner")}
                            </span>

                            <span className="sectionDesc d-block mb-4 text-justify">
                                {translate(props.lang, "Bost Technology Limited operates under the vigilant oversight of regulatory bodies, ensuring strict adherence to financial industry regulations and standards. This commitment fosters a foundation of trust, where clients can rely with confidence on Bost Technology Limited's services. From Canada to Australia, Bost Technology Limited upholds a standard of professionalism and transparency in its operations, establishing a secure and dependable trading environment.")}
                            </span>

                            <div className="oversightImgPos">
                                <img src={require("../../assets/img/our-partner/oversight2.png").default} alt="" className="" />
                            </div>
                        </div>
                    </Col>

                    <Col lg={5} md={10} sm={12} xs={12}>
                        <div className="oversightDiv">
                            <span className="oversightTitle d-block mb-4">
                                {translate(props.lang, "Backed by Dual Regulatory Authority: Bost Technology Limited Ensures Trust and Compliance")}
                            </span>

                            <span className="sectionDesc d-block mb-5 text-justify">
                                {translate(props.lang, "Bost Technology Limited is held to account by two influential regulatory bodies: the Australian Securities and Investments Commission (ASIC)")}
                            </span>

                            <div className="oversightImgPos">
                                <img src={require("../../assets/img/our-partner/oversight3.png").default} alt="" className="" />
                            </div>
                        </div>
                    </Col>

                    <Col lg={5} md={10} sm={12} xs={12}>
                        <div className="oversightDiv">
                            <span className="oversightTitle d-block mb-4">
                                {translate(props.lang, "Bost Technology Limited: Where Professionalism Meets Transparency in Business Operations")}
                            </span>

                            <span className="sectionDesc d-block mb-5 text-justify">
                                {translate(props.lang, "Backed by respected regulatory certifications, Bost Technology Limited operates with transparency, ensuring adherence to financial standards and building customer trust. We prioritize professionalism, delivering a secure trading environment for our clients.")}
                            </span>

                            <div className="oversightImgPos">
                                <img src={require("../../assets/img/our-partner/oversight4.png").default} alt="" className="" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Zoom>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Oversight);