import React from "react";
import { Col, Row } from "reactstrap";
import Zoom from "react-reveal/Zoom";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const Methodologies = (props) => {

    return (
        <>
            <Row className="justify-content-center align-items-center mx-0 position-relative" id="methodologies">
                <Col lg={12} md={12} sm={12} xs={12} className="">
                    <div className="text-center">
                        <span className="methodologiesTitle d-block">
                            {translate(props.lang, "At Quantbridge,")}
                        </span>
                        <span className="methodologiesTitle d-block">
                            {translate(props.lang, "our operations are guided by four critical methodologies")}
                        </span>

                        <Row className="justify-content-center align-items-start mx-0 position-relative mt-5 pt-5">
                            <Col lg={5} md={6} sm={12} xs={12} className="mb-5">
                                <Zoom duration={1500}>
                                    <div className="position-relative">
                                        <img src={require("../../assets/img/aboutUs/method1.png").default} alt="" className="w-100" />
                                        <div className="methodContentPos px-2 px-lg-5 text-justify">
                                            <span className="sectionDesc text-justify d-block">{translate(props.lang, "Preserving and growing clients' assets through effective asset management solutions.")}</span>
                                        </div>
                                    </div>
                                </Zoom>
                            </Col>

                            <Col lg={5} md={6} sm={12} xs={12} className="mb-5">
                                <Zoom duration={2000}>
                                    <div className="position-relative">
                                        <img src={require("../../assets/img/aboutUs/method2.png").default} alt="" className="w-100" />
                                        <div className="methodContentPos px-2 px-lg-5 text-justify">
                                            <span className="sectionDesc text-justify d-block">{translate(props.lang, "Offering a gateway for investors to access highly experienced institutional traders.")}</span>
                                        </div>
                                    </div>
                                </Zoom>
                            </Col>

                            <Col lg={5} md={6} sm={12} xs={12} className="mb-5">
                                <Zoom duration={2500}>
                                    <div className="position-relative">
                                        <img src={require("../../assets/img/aboutUs/method3.png").default} alt="" className="w-100" />
                                        <div className="methodContentPos px-2 px-lg-5 text-justify">
                                            <span className="sectionDesc text-justify d-block">{translate(props.lang, "Ensuring secure investments through segregated accounts with top-tier brokers/banks.")}</span>
                                        </div>
                                    </div>
                                </Zoom>
                            </Col>

                            <Col lg={5} md={6} sm={12} xs={12} className="mb-5">
                                <Zoom duration={3000}>
                                    <div className="position-relative">
                                        <img src={require("../../assets/img/aboutUs/method4.png").default} alt="" className="w-100" />
                                        <div className="methodContentPos px-2 px-lg-5 text-justify">
                                            <span className="sectionDesc text-justify d-block">{translate(props.lang, "Providing our investing clients with complete transparency at all times.")}</span>
                                        </div>
                                    </div>
                                </Zoom>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Methodologies);