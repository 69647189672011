import React from "react";
import { Col, Row } from "reactstrap";
import Fade from "react-reveal/Fade";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const OurMission = (props) => {

    return (
        <>
            <Row className="justify-content-center justify-content-lg-start align-items-center mx-0 position-relative" id="ourMission">
                <Col lg={6} md={8} sm={11} xs={11} className="pl-0 mb-4">
                    <img src={require("../../assets/img/aboutUs/missionImg.png").default} alt="mission" className="w-100" />
                </Col>
                <Col lg={5} md={11} sm={12} xs={12} className="">
                    <Fade left duration={1500}>
                        <div className="text-center text-lg-left">
                            <span className="sectionTitle d-block text-center text-lg-left">
                                {translate(props.lang, "Our Mission")}
                            </span>

                            <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-5" />
                        </div>

                        <Row className="justify-content-start align-items-start mx-0 position-relative mt-5">
                            <Col lg={2} md={2} sm={2} xs={2} className="">
                                <span className="missionIndexText">
                                    i
                                </span>
                            </Col>
                            <Col lg={10} md={10} sm={10} xs={10} className="pl-0 mb-4">
                                <span className="sectionDesc">
                                    {translate(props.lang, "At Quantbridge, we excel in making our investors happy. With unwavering commitment, trustworthiness, and superior financial expertise, we dedicate our proprietary assets to amplify your wealth and exceed your expectations.")}
                                </span>
                            </Col>

                            <Col lg={2} md={2} sm={2} xs={2} className="">
                                <span className="missionIndexText">
                                    ii
                                </span>
                            </Col>
                            <Col lg={10} md={10} sm={10} xs={10} className="pl-0 mb-4">
                                <span className="sectionDesc">
                                    {translate(props.lang, "Enhance your investment portfolio with QUANTBRIDGE-managed accounts, ensuring superior market performance and careful capital management even in unstable conditions.")}
                                </span>
                            </Col>

                            <Col lg={2} md={2} sm={2} xs={2} className="">
                                <span className="missionIndexText">
                                    iii
                                </span>
                            </Col>
                            <Col lg={10} md={10} sm={10} xs={10} className="pl-0 mb-4">
                                <span className="sectionDesc">
                                    {translate(props.lang, "Fueled by an unwavering passion for profitability, we persistently seek intellectual innovation, crafting golden solutions that consistently build fortunes for our clients.")}
                                </span>
                            </Col>

                            <Col lg={2} md={2} sm={2} xs={2} className="">
                                <span className="missionIndexText">
                                    iv
                                </span>
                            </Col>
                            <Col lg={10} md={10} sm={10} xs={10} className="pl-0 mb-4">
                                <span className="sectionDesc">
                                    {translate(props.lang, "Our success is founded on essential values that drive us to achieve the extraordinary with the utmost integrity. Clients trust us with their money, knowing we handle it as if it were our own.")}
                                </span>
                            </Col>
                        </Row>
                    </Fade>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(OurMission);