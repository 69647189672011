import React from "react";
import "../../assets/scss/ourTeam.scss";
import OurTeamHeader from "./OurTeamHeader";
import OurPhilosophy from "./OurPhilosophy";
import CustomizedApproach from "./CustomizedApproach";
import ProvenApproach from "./ProvenApproach";
import StrategicApproach from "./StrategicApproach";
import RiskManagement from "./RiskManagement";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import ScrollToTop from "react-scroll-to-top";
import { FaAngleUp } from "react-icons/fa";
import { Table } from "reactstrap";
// import EN from "../../language/en";

const OurTeam = () => {

    return (
        <>
            <IndexNavbar />
            <div className="ourTeam">
                <OurTeamHeader />
                <OurPhilosophy />
                <CustomizedApproach />
                <ProvenApproach />
                <StrategicApproach />
                <RiskManagement />

                {/* <Table>
                    {EN && Object.keys(EN).map((data, i) => {
                        return (
                            <tr>
                                <td>
                                    <span>{EN[data]}</span>
                                </td>
                            </tr>
                        );
                    })}
                </Table> */}
            </div>
            <DarkFooter />
            <ScrollToTop smooth className="backToTopBtn" style={{ background: "linear-gradient(180deg, #1F4C8E -113%, #001550 190.5%)" }} component={<div><FaAngleUp className="scrollTopIcon" color="#fff" /></div>} />
        </>
    );
};

export default OurTeam;
