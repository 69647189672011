import React from "react";
import { Col, Row } from "reactstrap";
import Zoom from "react-reveal/Zoom";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const OurValue = (props) => {

    return (
        <>
            <Row className="justify-content-center align-items-center mx-0 position-relative" id="ourValue">
                <Col lg={12} md={12} sm={12} xs={12} className="">
                    <div className="text-center">
                        <span className="sectionTitle">
                            {translate(props.lang, "Our Value")}
                        </span>
                        <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="mt-3 d-block text-center mx-auto" />
                    </div>

                    <Row className="justify-content-center align-items-center mx-0 position-relative mt-5">
                        <Col lg={7} md={11} sm={12} xs={12} className="mb-3 order-1 mb-lg-0">
                            <img src={require("../../assets/img/aboutUs/value1.png").default} alt="" className="w-100" />
                        </Col>
                        <Col lg={4} md={10} sm={11} xs={11} className="pl-0 mb-4 order-2 mb-5 mb-lg-0">
                            <Zoom duration={1500}>
                                <div className="px-0 px-lg-5 valueContentPos1">
                                    <span className="valueContentTitle d-block text-left mb-4">{translate(props.lang, "OUR TEAM")}</span>
                                    <span className="sectionDesc">
                                        {translate(props.lang, "We have a dedicated team of brilliant and experienced traders who are fueled by a passion for profits. Their greatest joy comes from witnessing our investors' portfolio balances grow every day.")}
                                    </span>
                                </div>
                            </Zoom>
                        </Col>

                        <Col lg={4} md={10} sm={11} xs={11} className="pl-0 mb-4 order-4 order-lg-3 mb-5 mb-lg-0">
                            <Zoom duration={1500}>
                                <div className="px-0 px-lg-5 valueContentPos2 text-left text-lg-right">
                                    <span className="valueContentTitle d-block text-left text-lg-right mb-4">{translate(props.lang, "POWERFUL TECHNOLOGY TEAM-DRIVEN STRATEGIES")}</span>
                                    <span className="sectionDesc text-right">
                                        {translate(props.lang, "Our advanced computerized technology and skilled team continuously refine trading strategies to achieve optimal results quickly and precisely.")}
                                    </span>
                                </div>
                            </Zoom>
                        </Col>
                        <Col lg={7} md={11} sm={12} xs={12} className="order-3 order-lg-4 mb-3 mb-lg-0">
                            <img src={require("../../assets/img/aboutUs/value2.png").default} alt="" className="w-100" />
                        </Col>

                        <Col lg={7} md={11} sm={12} xs={12} className="mb-3 order-5 mb-lg-0">
                            <img src={require("../../assets/img/aboutUs/value3.png").default} alt="" className="w-100" />
                        </Col>
                        <Col lg={4} md={10} sm={11} xs={11} className="pl-0 mb-4 order-6 mb-5 mb-lg-0">
                            <Zoom duration={1500}>
                                <div className="px-0 px-lg-5 valueContentPos1">
                                    <span className="valueContentTitle d-block text-left mb-4">{translate(props.lang, "DRIVEN BY COMMITMENT & INNOVATION")}</span>
                                    <span className="sectionDesc">
                                        {translate(props.lang, "We are dedicated to our stakeholders' well-being, including our investors and employees. Our relentless pursuit of excellence extends to all aspects of our products, services, processes, and technology.")}
                                    </span>
                                </div>
                            </Zoom>
                        </Col>

                        <Col lg={4} md={10} sm={11} xs={11} className="pl-0 mb-4 order-7 order-lg-6 mb-5 mb-lg-0">
                            <Zoom duration={1500}>
                                <div className="px-0 px-lg-5 valueContentPos2 text-left text-lg-right">
                                    <span className="valueContentTitle d-block text-left text-lg-right mb-4">{translate(props.lang, "INTEGRITY IS OUR FOUNDATION")}</span>
                                    <span className="sectionDesc text-right">
                                        {translate(props.lang, "We prioritize honesty, transparency, professionalism, and accountability in all our interactions—with our team, stakeholders, the public, and especially our clients.")}
                                    </span>
                                </div>
                            </Zoom>
                        </Col>
                        <Col lg={7} md={11} sm={12} xs={12} className="order-6 order-lg-7 mb-3 mb-lg-0">
                            <img src={require("../../assets/img/aboutUs/value4.png").default} alt="" className="w-100" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(OurValue);