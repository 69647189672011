import React from "react";
import { Col, Row } from "reactstrap";
import Flip from "react-reveal/Flip";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const MarketType = (props) => {

    return (
        <>
            <div className="" id="marketType">
                <Row className="justify-content-center align-items-center mx-0">
                    <Col lg={4} md={9} sm={12} xs={12} className="mb-5">
                        <div className="text-center mb-4">
                            <span className="sectionTitle">
                                {translate(props.lang, "Dive into Diverse Markets")}
                            </span>
                        </div>

                        <div className="text-center">
                            <span className="sectionDesc">
                                {translate(props.lang, "QuantBridge isn't limited to gold alone. We excel in a multitude of markets, enriching your investment opportunities like never before:")}
                            </span>
                        </div>

                        <div className="text-center">
                            <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="mt-3" />
                        </div>
                    </Col>
                </Row>

                <Row className="justify-content-center align-items-center mx-0 mt-5">
                    <Col lg={11} md={12} sm={12} xs={12}>
                        <Row className="justify-content-center align-items-center mx-0">
                            <Col lg={3} md={6} sm={12} xs={12} className="mb-4">
                                <Flip top duration={1500}>
                                    <div className="marketTypeDiv text-center">
                                        <span className="marketTypeTitle d-block mb-4">
                                            {translate(props.lang, "Forex")}
                                        </span>

                                        <span className="sectionDesc d-block mb-5 pb-4">
                                            {translate(props.lang, "Harness the global currency landscape with QuantBridge's data-driven insights.")}
                                        </span>

                                        <div className="mb-4 pb-2">
                                            <img src={require("../../assets/img/market-diversity/market1.png").default} alt="" className="" />
                                        </div>
                                    </div>
                                </Flip>
                            </Col>

                            <Col lg={3} md={6} sm={12} xs={12} className="mb-4">
                                <Flip bottom duration={1500}>
                                    <div className="marketTypeDiv text-center">
                                        <div className="my-5 pb-3">
                                            <img src={require("../../assets/img/market-diversity/market2.png").default} alt="" className="" />
                                        </div>

                                        <span className="marketTypeTitle d-block mt-5 mb-4">
                                            {translate(props.lang, "Crude Oil")}
                                        </span>

                                        <span className="sectionDesc d-block mb-4">
                                            {translate(props.lang, "Seize rapid opportunities in the influential crude oil market.")}
                                        </span>
                                    </div>
                                </Flip>
                            </Col>

                            <Col lg={3} md={6} sm={12} xs={12} className="mb-4">
                                <Flip top duration={1500}>
                                    <div className="marketTypeDiv text-center">
                                        <span className="marketTypeTitle d-block mb-4">
                                            {translate(props.lang, "Metals")}
                                        </span>

                                        <span className="sectionDesc d-block mb-5 pb-5">
                                            {translate(props.lang, "From precious to industrial metals, benefit from our strategic trading solutions.")}
                                        </span>

                                        <div className="mb-2">
                                            <img src={require("../../assets/img/market-diversity/market3.png").default} alt="" className="" />
                                        </div>
                                    </div>
                                </Flip>
                            </Col>

                            <Col lg={3} md={6} sm={12} xs={12} className="mb-4">
                                <Flip bottom duration={1500}>
                                    <div className="marketTypeDiv text-center">
                                        <div className="my-5 pb-3">
                                            <img src={require("../../assets/img/market-diversity/market4.png").default} alt="" className="" />
                                        </div>

                                        <span className="marketTypeTitle d-block mt-5 mb-4">
                                            {translate(props.lang, "Indices")}
                                        </span>

                                        <span className="sectionDesc d-block mb-4">
                                            {translate(props.lang, "Experience diversified sectors through index trading.")}
                                        </span>
                                    </div>
                                </Flip>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12} className="mt-5">
                        <span className="sectionDesc d-block">
                            {translate(props.lang, "QuantBridge expands your trading panorama, letting you craft a comprehensive investment strategy. Embark on a journey beyond gold with QuantBridge.")}
                        </span>
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(MarketType);