import React from "react";
import { Col, Row } from "reactstrap";
import Zoom from "react-reveal/Zoom";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const OurPhilosophy = (props) => {

    return (
        <>
            <Row className="justify-content-center align-items-center mx-0 position-relative" id="ourPhilosophy">
                <Col lg={10} md={11} sm={12} xs={12}>
                    <Zoom duration={1500}>
                        <div className="text-left ourPhilosophyBox">
                            <Row className="justify-content-evenly align-items-center mx-0">
                                <Col lg={6} md={12} sm={12} xs={12} className="px-0">
                                    <img src={require("../../assets/img/ourTeam/philosophyImg.png").default} alt="" className="philosophyImg w-100" />

                                </Col>
                                <Col lg={6} md={12} sm={12} xs={12} className="px-5 pb-5">
                                    <span className="sectionTitle d-block text-left">
                                        {translate(props.lang, "Our Philosophy")}
                                    </span>
                                    <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4" />
                                    <span className="sectionDesc d-block text-justify">
                                        {translate(props.lang, "At Quantbridge, we focus on generating steady returns over the medium to long term, irrespective of market conditions. Our adaptability and patience allow us to stay ahead in ever-changing markets. We develop new investment strategies to suit any environment, providing access to specialized markets with promising rewards. Patience is our virtue, minimizing risks and maximizing returns for our valued clients. Trust in Quantbridge for a rewarding investment journey.")}
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    </Zoom>
                </Col>
                <img src={require("../../assets/img/ourTeam/philosophyDeco.png").default} alt="" className="philosophyDeco" />
            </Row>

        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(OurPhilosophy);