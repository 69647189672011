import React from "react";
import { Col, Row } from "reactstrap";
import { MouseParallax } from 'react-just-parallax';
import Fade from "react-reveal/Fade";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const InvestHappiness = (props) => {

    return (
        <>
            <Row className="justify-content-center align-items-center mx-0 position-relative" id="investHappiness">
                <Col lg={7} md={11} sm={12} xs={12} className="investHappinessContent">
                    <Fade bottom duration={1500}>
                        <div className="text-center px-0 px-md-5">
                            <span className="sectionTitle">
                                {translate(props.lang, "INVESTMENT HAPPINESS REDEFINED")}
                            </span>
                        </div>
                    </Fade>

                    <Fade top duration={1500}>
                        <div className="text-center mt-5">
                            <span className="sectionDesc">
                                {translate(props.lang, "Welcome to Quantbridge, the asset management group excelling in proprietary trading. With advanced financial players and cutting-edge technologies, we deliver maximum profits with minimal risk. Backed by 15 years of private fund management experience and over 20 years in financial quantitative analytics and trading, we offer personalized solutions to turn your dreams into reality. Join us on this exhilarating journey to investment happiness!")}
                            </span>
                        </div>
                    </Fade>
                </Col>

                <img src={require("../../assets/img/home/happyInvestBg.png").default} alt="happyInvest" className="happyInvestBg" />

                <MouseParallax isAbsolutelyPositioned strength={0.3} lerpEase={0.5}>
                    <img src={require("../../assets/img/home/happyInvestParallax.png").default} alt="happyInvest" className="happyInvestParallax" />
                </MouseParallax>
            </Row>

            <Row className="justify-content-center justify-content-lg-start align-items-center mx-0 position-relative py-5" id="happinessDetailSection">
                <Col lg={6} md={8} sm={11} xs={11} className="pl-0 mb-4">
                    <Fade top duration={1500}>
                        <img src={require("../../assets/img/home/happinessImg.png").default} alt="happyInvest" className="w-100" />
                    </Fade>
                </Col>
                <Col lg={5} md={8} sm={11} xs={11} className="happinessDetailSection">
                    <Fade bottom duration={1500}>
                        <div className="text-left">
                            <span className="happinessDetailTitle">
                                {translate(props.lang, "Investment Happiness Assured")}
                            </span>
                        </div>

                        <div className="text-left mt-5">
                            <span className="sectionDesc">
                                {translate(props.lang, "At Quantbridge, we excel in making our investors happy. With unwavering commitment, trustworthiness, and superior financial expertise, we dedicate our proprietary assets to amplify your wealth and exceed your expectations.")}
                            </span>
                        </div>

                        <div className="btnDiv2">
                            <a href="/about-us">
                                <div className="learnMoreBtn text-left mt-5 d-flex justify-content-center align-items-center">
                                    <span className="mr-5">
                                        {translate(props.lang, "Learn More")}
                                    </span>

                                    <div>
                                        <span className="">></span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Fade>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(InvestHappiness);