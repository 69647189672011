import React from "react";
import { Col, Row } from "reactstrap";
import Fade from "react-reveal/Fade";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const AboutUsIntro = (props) => {

    return (
        <>
            <Row className="justify-content-center align-items-center mx-0 position-relative" id="aboutUsIntro">
                <Col lg={12} md={12} sm={12} xs={12} className="">
                    <div className="text-center">
                        <span className="sectionTitle d-block">
                            {translate(props.lang, "About Us")}
                        </span>

                        <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="mt-3" />

                        <Row className="justify-content-center align-items-start mx-0 position-relative aboutUsIntroContent">
                            <Col lg={5} md={12} sm={12} xs={12} className="">
                                <Fade top duration={1500}>
                                    <span className="sectionDesc text-left d-block mb-3">
                                        {translate(props.lang, "Welcome to Quantbridge, where we thrive in proprietary trading, leveraging our expertise and advanced technology to achieve financial wealth for our investors.")}
                                    </span>
                                    <span className="sectionDesc text-left d-block mb-3">
                                        {translate(props.lang, "With a strong suite of investment portfolios, we aim to broaden your options and maximize returns. As your ideal investment partner, we offer personalized consultancy, dynamic products, and professional traders dedicated to your best interests.")}
                                    </span>
                                    <span className="sectionDesc text-left d-block mb-3">
                                        {translate(props.lang, "Our clients rely on our proven expertise in portfolio diversification and management. We provide comprehensive advisory services with complete transparency, earning their full trust alongside excellent returns.")}
                                    </span>
                                </Fade>
                            </Col>

                            <Col lg={5} md={12} sm={12} xs={12} className="">
                                <Fade top duration={2000}>
                                    <span className="sectionDesc text-left d-block mb-3">
                                        {translate(props.lang, "Our team of talented traders boasts experience from renowned international investment banks and hedge funds. Their vast knowledge and innovative strategies, powered by a robust technological platform, ensure consistent market success.")}
                                    </span>
                                    <span className="sectionDesc text-left d-block mb-3">
                                        {translate(props.lang, "With a diverse client base spanning more than 30 countries, our reputation for excellence continues to attract affluent clients as we expand our presence across Asia.")}
                                    </span>
                                </Fade>
                            </Col>
                        </Row>
                    </div>


                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(AboutUsIntro);