import React from "react";
import { Col, Row } from "reactstrap";
import Fade from "react-reveal/Fade";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const Impact = (props) => {

    return (
        <>
            <Row className="justify-content-center justify-content-lg-start align-items-center mx-0 position-relative" id="impactSection">
                <Col lg={6} md={8} sm={11} xs={11} className="pl-0 mb-4">
                    <img src={require("../../assets/img/goldWave/impactImg.png").default} alt="happyInvest" className="w-100" />
                </Col>
                <Col lg={5} md={8} sm={11} xs={11} className="impactSectionContent">
                    <Fade left duration={1500}>
                        <div className="text-left">
                            <span className="sectionTitle">
                                {translate(props.lang, "Global Events Impacting XAU/USD")}
                            </span>
                        </div>

                        <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="mt-5" />

                        <div className="text-left mt-5">
                            <span className="sectionDesc">
                                {translate(props.lang, "XAU/USD's response to global events like trade wars is grounded in gold's role as a safe-haven asset. During uncertainties, investors often turn to gold for stability, driving its price higher. This relationship, coupled with the pairing's inverse correlation to the US dollar, positions XAU/USD favourably. As trade tensions rise, XAU/USD benefits from gold's safe-haven appeal, offering potential trading opportunities.")}
                            </span>
                        </div>
                    </Fade>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Impact);