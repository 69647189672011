import React from "react";
import { Col, Row } from "reactstrap";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { FaMinus, FaPlus } from "react-icons/fa";
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const whyUsAccordion = [
    {
        title: 'POWERFUL TEAM, STRONG RESULTS',
        desc:
            `<ul>
        <li>Expert traders and technologists with vast experience handling substantial funds and swift market reactions.</li>
        <li>Portfolio managers with over 20 years of expertise in financial quantitative analytics and trading.</li>
        <li>The best traders in the industry, having served tier 1 banks and hedge funds, ensuring outstanding results.</li>
        <li>Advisory mandates are tailored for investors seeking customized advice and control over their portfolios.</li>
        </ul>
        `
    },
    {
        title: 'REGULATED & LICENSED',
        desc: 'Quantbridge is approved and licensed by The British Virgin Islands Financial Services Commission, ensuring compliance and safeguarding your investments.'
    },
    {
        title: 'SAFE FUNDS. LOYAL CLIENTS',
        desc: 'Your funds are secure in segregated accounts at Tier 1 Bank, protected by advanced encryption technology. With over US$100 million A.U.M managed for clients in 30 countries, our growing success is fueled by loyal clients like you.'
    },
    {
        title: 'TRANSPARENT & ACCESSIBLE',
        desc: "At Quantbridge, transparency is paramount. We believe in full disclosure, ensuring clients have visibility into how their money is handled. You can access your accounts anytime and receive regular statements, providing a clear view of your account's performance."
    },
    {
        title: 'EMPHASIZING MAXIMUM PROFITS',
        desc: 'At Quantbridge, profits are our priority. Our carefully calibrated trading methodologies, strategies, and personal investment in each portfolio drive us to achieve maximum returns. Your success is our commitment.'
    },
]

const WhyUs = (props) => {
    const [selectedContent, setSelectedContent] = React.useState(0);

    return (
        <>
            <div className="" id="whyUs">
                <Row className="justify-content-center align-items-start mx-0 position-relative strategicPartnerRow">
                    <Col lg={4} md={11} sm={12} xs={12} className="mb-5">
                        <div className="text-center text-lg-left">
                            <span className="sectionTitle d-block text-center text-lg-left">
                                {translate(props.lang, "Why")}
                            </span>
                            <span className="sectionTitle d-block text-center text-lg-left mb-3">
                                {translate(props.lang, "Quantbridge")}
                            </span>
                            <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4 d-block mx-auto mx-lg-0 mr-lg-auto" />

                            <div className="mt-5 text-left">
                                <span className="whyUsTitle d-block mb-4">{translate(props.lang, "INVESTMENT HAPPINESS ASSURED")}</span>
                                < span className="sectionDesc d-block text-justify" >
                                    {translate(props.lang, "At Quantbridge, we take immense pride in ensuring the happiness of our investors. You can rest easy knowing that you have a reliable ally who is wholeheartedly committed to your investment goals. With an exceptional team possessing superior financial expertise and vast experience, we are strengthened by awe-inspiring proprietary assets devoted solely to expanding your wealth and surpassing your expectations. Trust in Quantbridge to unlock your path to investment happiness.")}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} md={12} sm={12} xs={12} className=" d-none d-lg-block" />

                    <Col lg={5} md={11} sm={12} xs={12} className="">
                        <Accordion preExpanded={[0]} className="text-left borderTopWhite">
                            {whyUsAccordion.map((item, i) => (
                                <AccordionItem key={i} uuid={i} className={i === selectedContent ? "accordion-item borderBottomWhite" : "accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent(i)}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <div className="row justify-content-center align-items-center">
                                                <div className="col-10 px-0">
                                                    <div className="">
                                                        <span className="whyUsTitle">
                                                            {translate(props.lang, item.title)}
                                                        </span>

                                                    </div>
                                                </div>
                                                <div className="col-2 px-0">
                                                    <div className="text-right">
                                                        <FaMinus className={`h3 my-0 accordionIcon ${i === selectedContent ? "selectedExpandIcon" : "nonselectedExpandIcon"}`} style={{ display: i === selectedContent ? "none" : "block" }} />
                                                        <FaPlus className={`h3 my-0 accordionIcon ${i === selectedContent ? "nonselectedExpandIcon" : "selectedExpandIcon"}`} style={{ display: i === selectedContent ? "block" : "none" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel style={{ display: i === selectedContent ? "block" : "none" }}>
                                        <div className="row justify-content-start align-items-center">
                                            <div className="col-11">
                                                <div className="accordionItemPanelPoint">
                                                    <span className="sectionDesc text-left">
                                                        {ReactHtmlParser(item.desc)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </Col>

                    <img src={require("../../assets/img/aboutUs/whyUsBg.png").default} alt="" className="whyUsDecoPos" />
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(WhyUs);