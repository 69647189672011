import React from "react";
import { Col, Row } from "reactstrap";
import { gsap, ScrollTrigger } from "gsap/all";
import Zoom from "react-reveal/Zoom";
import Flip from "react-reveal/Flip";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const StrategicApproach = (props) => {

    React.useEffect(() => {
        let sections = gsap.utils.toArray(".panel");

        let scrollTween = gsap.to(sections, {
            xPercent: -100 * (sections.length - 1),
            ease: "none", // <-- IMPORTANT!
            scrollTrigger: {
                trigger: ".approachScroll",
                pin: true,
                scrub: 0.1,
                //snap: directionalSnap(1 / (sections.length - 1)),
                end: "+=5000"
            }
        });
    }, []);

    return (
        <>
            <div className="" id="strategicApproach">
                <div className="d-none d-lg-block">
                    <div className="approachScroll">
                        <div className="panel">
                            <Row className="justify-content-end align-items-center min-height-100 mx-0">
                                <Col lg={5} md={4} sm={11} xs={11}>
                                    <div className="text-center text-lg-left">
                                        <span className="sectionTitle d-block text-center text-lg-left">
                                            {translate(props.lang, "OUR STRATEGIC")}
                                        </span>
                                        <span className="sectionTitle d-block text-center text-lg-left mb-4">
                                            {translate(props.lang, "TRADING APPROACH")}
                                        </span>

                                        <span className="sectionDesc d-block text-center text-lg-left">
                                            {translate(props.lang, "At Quantbridge, our trading is purposeful and guided by specific constraints:")}
                                        </span>

                                        <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4 d-block mx-auto mx-lg-0 mr-lg-auto" />
                                    </div>
                                </Col>

                                <Col lg={3} md={3} sm={11} xs={11}>
                                    <Flip top duration={1500}>
                                        <div className="approachBox position-relative text-center py-5 px-4">
                                            <div className="advantagesBoxTitle mb-3">
                                                <span className="d-block mb-5">{translate(props.lang, "Exceeding Benchmarks")}</span>
                                                <img src={require("../../assets/img/ourTeam/approach1.png").default} alt="" className="approachImgSize" />
                                                <span className="sectionDesc d-block mt-5">{translate(props.lang, "Targeting total annualized net returns above the benchmark over any 3-year period, ensuring consistent performance.")}</span>
                                            </div>
                                        </div>
                                    </Flip>
                                </Col>
                                <Col lg={3} md={3} sm={11} xs={11}>
                                    <Flip bottom duration={1500}>
                                        <div className="approachBox position-relative text-center py-5 px-4">
                                            <div className="advantagesBoxTitle mb-3">
                                                <span className="d-block mb-5">{translate(props.lang, "Diverse Access")}</span>
                                                <img src={require("../../assets/img/ourTeam/approach2.png").default} alt="" className="approachImgSize" />
                                                <span className="sectionDesc d-block mt-5">{translate(props.lang, "We offer clients access to FX, futures, and commodities, expanding their investment horizons.")}</span>
                                            </div>
                                        </div>
                                    </Flip>
                                </Col>
                            </Row>
                        </div>

                        <div className="panel">
                            <Row className="justify-content-start align-items-center min-height-100 mx-0">
                                <Col lg={3} md={3} sm={11} xs={11}>
                                    <Flip top duration={1500}>
                                        <div className="approachBox position-relative text-center py-5 px-4">
                                            <div className="advantagesBoxTitle mb-3">
                                                <span className="d-block mb-5">{translate(props.lang, "Capital Preservation")}</span>
                                                <img src={require("../../assets/img/ourTeam/approach3.png").default} alt="" className="approachImgSize" />
                                                <span className="sectionDesc d-block mt-5">{translate(props.lang, "Our priority is to preserve capital, with a maximum drawdown constraint of 20-30%, minimizing risks.")}</span>
                                            </div>
                                        </div>
                                    </Flip>
                                </Col>
                                <Col lg={3} md={3} sm={11} xs={11}>
                                    <Flip bottom duration={1500}>
                                        <div className="approachBox position-relative text-center py-5 px-4">
                                            <div className="advantagesBoxTitle mb-3">
                                                <span className="d-block mb-5">{translate(props.lang, "Currency Hedging")}</span>
                                                <img src={require("../../assets/img/ourTeam/approach4.png").default} alt="" className="approachImgSize" />
                                                <span className="sectionDesc d-block mt-5">{translate(props.lang, "Utilizing currency contracts to hedge against rate fluctuations, safeguarding your investments.")}</span>
                                            </div>
                                        </div>
                                    </Flip>
                                </Col>
                            </Row>
                        </div>
                        <img src={require("../../assets/img/ourTeam/gsapDeco.png").default} alt="" className="gsapDeco" />
                    </div>
                </div>

                <div className="d-block d-lg-none">
                    <Row className="justify-content-center align-items-center min-height-100 mx-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-5">
                            <div className="text-center text-lg-left">
                                <span className="sectionTitle d-block text-center text-lg-left">
                                    {translate(props.lang, "OUR STRATEGIC")}
                                </span>
                                <span className="sectionTitle d-block text-center text-lg-left mb-4">
                                    {translate(props.lang, "TRADING APPROACH")}
                                </span>

                                <span className="sectionDesc d-block text-center text-lg-left">
                                    {translate(props.lang, "At Quantbridge, our trading is purposeful and guided by specific constraints:")}
                                </span>

                                <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4 d-block mx-auto mx-lg-0 mr-lg-auto" />
                            </div>
                        </Col>

                        <Col lg={3} md={6} sm={11} xs={11} className="mb-3">
                            <Zoom duration={1500}>
                                <div className="approachBox position-relative text-center py-5 px-4">
                                    <div className="advantagesBoxTitle mb-3">
                                        <span className="d-block mb-5">{translate(props.lang, "Exceeding Benchmarks")}</span>
                                        <img src={require("../../assets/img/ourTeam/approach1.png").default} alt="" className="approachImgSize" />
                                        <span className="sectionDesc d-block mt-5">{translate(props.lang, "Targeting total annualized net returns above the benchmark over any 3-year period, ensuring consistent performance.")}</span>
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                        <Col lg={3} md={6} sm={11} xs={11} className="mb-3">
                            <Zoom duration={1500}>
                                <div className="approachBox position-relative text-center py-5 px-4">
                                    <div className="advantagesBoxTitle mb-3">
                                        <span className="d-block mb-5">{translate(props.lang, "Diverse Access")}</span>
                                        <img src={require("../../assets/img/ourTeam/approach2.png").default} alt="" className="approachImgSize" />
                                        <span className="sectionDesc d-block mt-5">{translate(props.lang, "We offer clients access to FX, futures, and commodities, expanding their investment horizons.")}</span>
                                    </div>
                                </div>
                            </Zoom>
                        </Col>

                        <Col lg={3} md={6} sm={11} xs={11} className="mb-3">
                            <Zoom duration={1500}>
                                <div className="approachBox position-relative text-center py-5 px-4 px-md-2">
                                    <div className="advantagesBoxTitle mb-3">
                                        <span className="d-block mb-5">{translate(props.lang, "Capital Preservation")}</span>
                                        <img src={require("../../assets/img/ourTeam/approach3.png").default} alt="" className="approachImgSize" />
                                        <span className="sectionDesc d-block mt-5">{translate(props.lang, "Our priority is to preserve capital, with a maximum drawdown constraint of 20-30%, minimizing risks.")}</span>
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                        <Col lg={3} md={6} sm={11} xs={11} className="mb-3">
                            <Zoom duration={1500}>
                                <div className="approachBox position-relative text-center py-5 px-4">
                                    <div className="advantagesBoxTitle mb-3">
                                        <span className="d-block mb-5">{translate(props.lang, "Currency Hedging")}</span>
                                        <img src={require("../../assets/img/ourTeam/approach4.png").default} alt="" className="approachImgSize" />
                                        <span className="sectionDesc d-block mt-5">{translate(props.lang, "Utilizing currency contracts to hedge against rate fluctuations, safeguarding your investments.")}</span>
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(StrategicApproach);