const EN = {
  //navbar
  "Login": "Login",
  "Open an account": "Open an account",
  "Homepage": "Homepage",
  "About Us": "About Us",
  "Our Partner": "Our Partner",
  "Trading": "Trading",
  "Our Strategies": "Our Strategies",
  "GoldWave": "GoldWave",
  "Market Diversity": "Market Diversity",
  "Resource": "Resource",
  "Trading View": "Trading View",
  "How to Invest": "How to Invest",
  "Our Team": "Our Team",
  "Our Careers": "Our Careers",
  "Contact Us": "Contact Us",
  "MENU": "MENU",

  //footer
  "QUANTBRIDGE: THRIVING THROUGH PROPRIETARY TRADING": "QUANTBRIDGE: THRIVING THROUGH PROPRIETARY TRADING",
  "We generate financial wealth for our investors, leveraging expertise and technology to gain a clear competitive edge. Trust Quantbridget for your financial prosperity.": "We generate financial wealth for our investors, leveraging expertise and technology to gain a clear competitive edge. Trust Quantbridget for your financial prosperity.",
  "REGIONAL RESTRICTIONS:": "REGIONAL RESTRICTIONS:",
  "We do not offer our services to residents of certain jurisdictions such as North Korea, Japan, United States and some other regions.": "We do not offer our services to residents of certain jurisdictions such as North Korea, Japan, United States and some other regions.",
  "Copyright © 2023 Quantbridge Capital. All rights reserved.": "Copyright © 2023 Quantbridge Capital. All rights reserved.",

  //home
  "Welcome To Quantbridge Capital": "Welcome To Quantbridge Capital",
  "INVESTMENT HAPPINESS REDEFINED": "INVESTMENT HAPPINESS REDEFINED",
  "Welcome to Quantbridge, the asset management group excelling in proprietary trading. With advanced financial players and cutting-edge technologies, we deliver maximum profits with minimal risk. Backed by 15 years of private fund management experience and over 20 years in financial quantitative analytics and trading, we offer personalized solutions to turn your dreams into reality. Join us on this exhilarating journey to investment happiness!": "Welcome to Quantbridge, the asset management group excelling in proprietary trading. With advanced financial players and cutting-edge technologies, we deliver maximum profits with minimal risk. Backed by 15 years of private fund management experience and over 20 years in financial quantitative analytics and trading, we offer personalized solutions to turn your dreams into reality. Join us on this exhilarating journey to investment happiness!",
  "Investment Happiness Assured": "Investment Happiness Assured",
  "At Quantbridge, we excel in making our investors happy. With unwavering commitment, trustworthiness, and superior financial expertise, we dedicate our proprietary assets to amplify your wealth and exceed your expectations.": "At Quantbridge, we excel in making our investors happy. With unwavering commitment, trustworthiness, and superior financial expertise, we dedicate our proprietary assets to amplify your wealth and exceed your expectations.",
  "Learn More": "Learn More",
  "OUR CLIENT": "OUR CLIENT",
  "Michael Reynolds": "Michael Reynolds",
  "My investments have flourished since joining forces with QuantBridge. Their professional and responsive team has made me feel exceptionally valued.": "My investments have flourished since joining forces with QuantBridge. Their professional and responsive team has made me feel exceptionally valued.",
  "Sarah Thompson": "Sarah Thompson",
  "QuantBridge has revolutionized my trading experience. Their unmatched expertise in strategies, coupled with an incredibly user-friendly platform, has been a game-changer.": "QuantBridge has revolutionized my trading experience. Their unmatched expertise in strategies, coupled with an incredibly user-friendly platform, has been a game-changer.",
  "Lisa Garcia": "Lisa Garcia",
  "Utilizing QuantBridge's copy trading on MT5 simplified my trading approach. I now trade confidently, achieving consistent returns.": "Utilizing QuantBridge's copy trading on MT5 simplified my trading approach. I now trade confidently, achieving consistent returns.",
  "John Parker": "John Parker",
  "QuantBridge's support and educational resources have been pivotal in my trading success. Their unwavering commitment to client growth is truly commendable.": "QuantBridge's support and educational resources have been pivotal in my trading success. Their unwavering commitment to client growth is truly commendable.",
  "Emma Stevens": "Emma Stevens",
  "I extend my heartfelt gratitude to QuantBridge for the invaluable opportunities they've provided. Their platform is a game-changer for aspiring traders.": "I extend my heartfelt gratitude to QuantBridge for the invaluable opportunities they've provided. Their platform is a game-changer for aspiring traders.",
  "Robert Lawson": "Robert Lawson",
  "QuantBridge has democratized investing, making it accessible and profitable. Their innovative tools and strategies have transformed me into a confident and prosperous trader.": "QuantBridge has democratized investing, making it accessible and profitable. Their innovative tools and strategies have transformed me into a confident and prosperous trader.",
  "Subscribe": "Subscribe",
  "Newsletter": "Newsletter",
  "You will never miss our latest news etc.": "You will never miss our latest news etc.",
  "We promise not to spam you!": "We promise not to spam you!",
  "Strategic": "Strategic",
  "Partners": "Partners",
  "Discover": "Discover",
  "At Quantbridge, we are accountable and transparent in handling your investments. Our streamlined procedures enable our team to focus on generating income on your behalf. Trust us to deliver efficiency and results.": "At Quantbridge, we are accountable and transparent in handling your investments. Our streamlined procedures enable our team to focus on generating income on your behalf. Trust us to deliver efficiency and results.",
  "Accountable": "Accountable",
  "Transparent": "Transparent",
  "Efficient": "Efficient",

  //aboutUs
  "Our Story and Values": "Our Story and Values",
  "Passion Igniting": "Passion Igniting",
  "Profits with Purpose": "Profits with Purpose",
  "Welcome to Quantbridge, where we thrive in proprietary trading, leveraging our expertise and advanced technology to achieve financial wealth for our investors.": "Welcome to Quantbridge, where we thrive in proprietary trading, leveraging our expertise and advanced technology to achieve financial wealth for our investors.",
  "With a strong suite of investment portfolios, we aim to broaden your options and maximize returns. As your ideal investment partner, we offer personalized consultancy, dynamic products, and professional traders dedicated to your best interests.": "With a strong suite of investment portfolios, we aim to broaden your options and maximize returns. As your ideal investment partner, we offer personalized consultancy, dynamic products, and professional traders dedicated to your best interests.",
  "Our clients rely on our proven expertise in portfolio diversification and management. We provide comprehensive advisory services with complete transparency, earning their full trust alongside excellent returns.": "Our clients rely on our proven expertise in portfolio diversification and management. We provide comprehensive advisory services with complete transparency, earning their full trust alongside excellent returns.",
  "Our team of talented traders boasts experience from renowned international investment banks and hedge funds. Their vast knowledge and innovative strategies, powered by a robust technological platform, ensure consistent market success.": "Our team of talented traders boasts experience from renowned international investment banks and hedge funds. Their vast knowledge and innovative strategies, powered by a robust technological platform, ensure consistent market success.",
  "With a diverse client base spanning more than 30 countries, our reputation for excellence continues to attract affluent clients as we expand our presence across Asia.": "With a diverse client base spanning more than 30 countries, our reputation for excellence continues to attract affluent clients as we expand our presence across Asia.",
  "At Quantbridge,": "At Quantbridge,",
  "our operations are guided by four critical methodologies": "our operations are guided by four critical methodologies",
  "Preserving and growing clients' assets through effective asset management solutions.": "Preserving and growing clients' assets through effective asset management solutions.",
  "Offering a gateway for investors to access highly experienced institutional traders.": "Offering a gateway for investors to access highly experienced institutional traders.",
  "Ensuring secure investments through segregated accounts with top-tier brokers/banks.": "Ensuring secure investments through segregated accounts with top-tier brokers/banks.",
  "Providing our investing clients with complete transparency at all times.": "Providing our investing clients with complete transparency at all times.",
  "Transparency": "Transparency",
  "Investment Strategy": "Investment Strategy",
  "Technology": "Technology",
  "Risk Management": "Risk Management",
  "Our Mission": "Our Mission",
  "At Quantbridge, we excel in making our investors happy. With unwavering commitment, trustworthiness, and superior financial expertise, we dedicate our proprietary assets to amplify your wealth and exceed your expectations.": "At Quantbridge, we excel in making our investors happy. With unwavering commitment, trustworthiness, and superior financial expertise, we dedicate our proprietary assets to amplify your wealth and exceed your expectations.",
  "Enhance your investment portfolio with QUANTBRIDGE-managed accounts, ensuring superior market performance and careful capital management even in unstable conditions.": "Enhance your investment portfolio with QUANTBRIDGE-managed accounts, ensuring superior market performance and careful capital management even in unstable conditions.",
  "Fueled by an unwavering passion for profitability, we persistently seek intellectual innovation, crafting golden solutions that consistently build fortunes for our clients.": "Fueled by an unwavering passion for profitability, we persistently seek intellectual innovation, crafting golden solutions that consistently build fortunes for our clients.",
  "Our success is founded on essential values that drive us to achieve the extraordinary with the utmost integrity. Clients trust us with their money, knowing we handle it as if it were our own.": "Our success is founded on essential values that drive us to achieve the extraordinary with the utmost integrity. Clients trust us with their money, knowing we handle it as if it were our own.",
  "Our Value": "Our Value",
  "OUR TEAM": "OUR TEAM",
  "We have a dedicated team of brilliant and experienced traders who are fueled by a passion for profits. Their greatest joy comes from witnessing our investors' portfolio balances grow every day.": "We have a dedicated team of brilliant and experienced traders who are fueled by a passion for profits. Their greatest joy comes from witnessing our investors' portfolio balances grow every day.",
  "POWERFUL TECHNOLOGY TEAM-DRIVEN STRATEGIES": "POWERFUL TECHNOLOGY TEAM-DRIVEN STRATEGIES",
  "Our advanced computerized technology and skilled team continuously refine trading strategies to achieve optimal results quickly and precisely.": "Our advanced computerized technology and skilled team continuously refine trading strategies to achieve optimal results quickly and precisely.",
  "DRIVEN BY COMMITMENT & INNOVATION": "DRIVEN BY COMMITMENT & INNOVATION",
  "We are dedicated to our stakeholders' well-being, including our investors and employees. Our relentless pursuit of excellence extends to all aspects of our products, services, processes, and technology.": "We are dedicated to our stakeholders' well-being, including our investors and employees. Our relentless pursuit of excellence extends to all aspects of our products, services, processes, and technology.",
  "INTEGRITY IS OUR FOUNDATION": "INTEGRITY IS OUR FOUNDATION",
  "We prioritize honesty, transparency, professionalism, and accountability in all our interactions—with our team, stakeholders, the public, and especially our clients.": "We prioritize honesty, transparency, professionalism, and accountability in all our interactions—with our team, stakeholders, the public, and especially our clients.",
  "Why": "Why",
  "Quantbridge": "Quantbridge",
  "INVESTMENT HAPPINESS ASSURED": "INVESTMENT HAPPINESS ASSURED",
  "At Quantbridge, we take immense pride in ensuring the happiness of our investors. You can rest easy knowing that you have a reliable ally who is wholeheartedly committed to your investment goals. With an exceptional team possessing superior financial expertise and vast experience, we are strengthened by awe-inspiring proprietary assets devoted solely to expanding your wealth and surpassing your expectations. Trust in Quantbridge to unlock your path to investment happiness.": "At Quantbridge, we take immense pride in ensuring the happiness of our investors. You can rest easy knowing that you have a reliable ally who is wholeheartedly committed to your investment goals. With an exceptional team possessing superior financial expertise and vast experience, we are strengthened by awe-inspiring proprietary assets devoted solely to expanding your wealth and surpassing your expectations. Trust in Quantbridge to unlock your path to investment happiness.",
  'POWERFUL TEAM, STRONG RESULTS': 'POWERFUL TEAM, STRONG RESULTS',
  'Expert traders and technologists with vast experience handling substantial funds and swift market reactions.': 'Expert traders and technologists with vast experience handling substantial funds and swift market reactions.',
  'Portfolio managers with over 20 years of expertise in financial quantitative analytics and trading.': 'Portfolio managers with over 20 years of expertise in financial quantitative analytics and trading.',
  'The best traders in the industry, having served tier 1 banks and hedge funds, ensuring outstanding results.': 'The best traders in the industry, having served tier 1 banks and hedge funds, ensuring outstanding results.',
  'Advisory mandates are tailored for investors seeking customized advice and control over their portfolios.': 'Advisory mandates are tailored for investors seeking customized advice and control over their portfolios.',
  'REGULATED & LICENSED': 'REGULATED & LICENSED',
  'Quantbridge is approved and licensed by The British Virgin Islands Financial Services Commission, ensuring compliance and safeguarding your investments.': 'Quantbridge is approved and licensed by The British Virgin Islands Financial Services Commission, ensuring compliance and safeguarding your investments.',
  'SAFE FUNDS. LOYAL CLIENTS': 'SAFE FUNDS. LOYAL CLIENTS',
  'Your funds are secure in segregated accounts at Tier 1 Bank, protected by advanced encryption technology. With over US$100 million A.U.M managed for clients in 30 countries, our growing success is fueled by loyal clients like you.': 'Your funds are secure in segregated accounts at Tier 1 Bank, protected by advanced encryption technology. With over US$100 million A.U.M managed for clients in 30 countries, our growing success is fueled by loyal clients like you.',
  'TRANSPARENT & ACCESSIBLE': 'TRANSPARENT & ACCESSIBLE',
  "At Quantbridge, transparency is paramount. We believe in full disclosure, ensuring clients have visibility into how their money is handled. You can access your accounts anytime and receive regular statements, providing a clear view of your account's performance.": "At Quantbridge, transparency is paramount. We believe in full disclosure, ensuring clients have visibility into how their money is handled. You can access your accounts anytime and receive regular statements, providing a clear view of your account's performance.",
  'EMPHASIZING MAXIMUM PROFITS': 'EMPHASIZING MAXIMUM PROFITS',
  'At Quantbridge, profits are our priority. Our carefully calibrated trading methodologies, strategies, and personal investment in each portfolio drive us to achieve maximum returns. Your success is our commitment.': 'At Quantbridge, profits are our priority. Our carefully calibrated trading methodologies, strategies, and personal investment in each portfolio drive us to achieve maximum returns. Your success is our commitment.',

  //our-partner
  "Bost Technology Limited": "Bost Technology Limited",
  "Elevate Your Trading": "Elevate Your Trading",
  "Experience with BOST": "Experience with BOST",
  "Safe and Transparent Trading with": "Safe and Transparent Trading with",
  "Dual Regulatory Oversight": "Dual Regulatory Oversight",
  "Bost Technology Limited: Pioneering the Way as a Premier and Innovative Trading Platform": "Bost Technology Limited: Pioneering the Way as a Premier and Innovative Trading Platform",
  "Bost Technology Limited is a trailblazing trading platform, dedicated to delivering an exceptional trading environment for clients. With an unwavering commitment to providing top-tier trading experiences, Bost Technology Limited continuously elevates its technology and platform, equipping traders with advanced tools to navigate market fluctuations adeptly. This proactive approach amplifies the prospects of trading success.": "Bost Technology Limited is a trailblazing trading platform, dedicated to delivering an exceptional trading environment for clients. With an unwavering commitment to providing top-tier trading experiences, Bost Technology Limited continuously elevates its technology and platform, equipping traders with advanced tools to navigate market fluctuations adeptly. This proactive approach amplifies the prospects of trading success.",
  "Your Secure and Reliable Trading Partner": "Your Secure and Reliable Trading Partner",
  "Bost Technology Limited operates under the vigilant oversight of regulatory bodies, ensuring strict adherence to financial industry regulations and standards. This commitment fosters a foundation of trust, where clients can rely with confidence on Bost Technology Limited's services. From Canada to Australia, Bost Technology Limited upholds a standard of professionalism and transparency in its operations, establishing a secure and dependable trading environment.": "Bost Technology Limited operates under the vigilant oversight of regulatory bodies, ensuring strict adherence to financial industry regulations and standards. This commitment fosters a foundation of trust, where clients can rely with confidence on Bost Technology Limited's services. From Canada to Australia, Bost Technology Limited upholds a standard of professionalism and transparency in its operations, establishing a secure and dependable trading environment.",
  "Backed by Dual Regulatory Authority: Bost Technology Limited Ensures Trust and Compliance": "Backed by Dual Regulatory Authority: Bost Technology Limited Ensures Trust and Compliance",
  "Bost Technology Limited is held to account by two influential regulatory bodies: the Australian Securities and Investments Commission (ASIC)": "Bost Technology Limited is held to account by two influential regulatory bodies: the Australian Securities and Investments Commission (ASIC)",
  "Bost Technology Limited: Where Professionalism Meets Transparency in Business Operations": "Bost Technology Limited: Where Professionalism Meets Transparency in Business Operations",
  "Backed by respected regulatory certifications, Bost Technology Limited operates with transparency, ensuring adherence to financial standards and building customer trust. We prioritize professionalism, delivering a secure trading environment for our clients.": "Backed by respected regulatory certifications, Bost Technology Limited operates with transparency, ensuring adherence to financial standards and building customer trust. We prioritize professionalism, delivering a secure trading environment for our clients.",
  "Bost Technology Limited CERTIFICATE": "Bost Technology Limited CERTIFICATE",

  //our-strategies
  "Our Strategies": "Our Strategies",
  "QuantBridge is your premier source for systematic trading, mathematical finance, and quantitative strategies. Our focus is boosting your trading profits and fostering a fulfilling career path with us.": "QuantBridge is your premier source for systematic trading, mathematical finance, and quantitative strategies. Our focus is boosting your trading profits and fostering a fulfilling career path with us.",
  "Quantitative Trading Expertise": "Quantitative Trading Expertise",
  "At QuantBridge, we offer comprehensive trading management services, ensuring seamless execution of your strategies for optimal results.": "At QuantBridge, we offer comprehensive trading management services, ensuring seamless execution of your strategies for optimal results.",
  "Expert Fund Management": "Expert Fund Management",
  "QuantBridge excels in expertly managing investment funds on behalf of our clients. Our dedicated team handles the daily operations and strategic decision-making to optimize the fund's investments": "QuantBridge excels in expertly managing investment funds on behalf of our clients. Our dedicated team handles the daily operations and strategic decision-making to optimize the fund's investments",
  "Masterful Trading Management": "Masterful Trading Management",
  "QuantBridge offers impeccable trading management services to ensure clients' trading strategies are executed with precision and effectiveness.": "QuantBridge offers impeccable trading management services to ensure clients' trading strategies are executed with precision and effectiveness.",
  "Fundamental Analysis": "Fundamental Analysis",
  "Explore the essence of informed decision-making through fundamental analysis. Understand how interest rates, production, earnings, and GDP drive markets. Navigate financial complexities with confidence.": "Explore the essence of informed decision-making through fundamental analysis. Understand how interest rates, production, earnings, and GDP drive markets. Navigate financial complexities with confidence.",

  //goldwave
  "Experience the GoldWave Advantage with QuantBridge": "Experience the GoldWave Advantage with QuantBridge",
  "Unveil the power of the GoldWave Strategy as it illuminates the XAU/USD pairing, reflecting gold's USD value, with a history of stability and consistent growth. The GoldWave Strategy, deeply rooted in this pairing, offers a structured approach that leverages gold's enduring value for consistent gains. Step into a world where opportunity converges with timeless value at QuantBridge. We understand that navigating financial markets requires a strategic touch. Our empowering trading strategies, including the specialized GoldWave Strategy, synergize with the benefits of gold (XAU/USD) trading to enhance your potential for success.": "Unveil the power of the GoldWave Strategy as it illuminates the XAU/USD pairing, reflecting gold's USD value, with a history of stability and consistent growth. The GoldWave Strategy, deeply rooted in this pairing, offers a structured approach that leverages gold's enduring value for consistent gains. Step into a world where opportunity converges with timeless value at QuantBridge. We understand that navigating financial markets requires a strategic touch. Our empowering trading strategies, including the specialized GoldWave Strategy, synergize with the benefits of gold (XAU/USD) trading to enhance your potential for success.",
  "Global Events Impacting XAU/USD": "Global Events Impacting XAU/USD",
  "XAU/USD's response to global events like trade wars is grounded in gold's role as a safe-haven asset. During uncertainties, investors often turn to gold for stability, driving its price higher. This relationship, coupled with the pairing's inverse correlation to the US dollar, positions XAU/USD favourably. As trade tensions rise, XAU/USD benefits from gold's safe-haven appeal, offering potential trading opportunities.": "XAU/USD's response to global events like trade wars is grounded in gold's role as a safe-haven asset. During uncertainties, investors often turn to gold for stability, driving its price higher. This relationship, coupled with the pairing's inverse correlation to the US dollar, positions XAU/USD favourably. As trade tensions rise, XAU/USD benefits from gold's safe-haven appeal, offering potential trading opportunities.",
  "GoldWave Strategy:": "GoldWave Strategy:",
  "Navigating Success with XAU/USD Trading": "Navigating Success with XAU/USD Trading",
  "Safe-Haven Approach": "Safe-Haven Approach",
  "Navigate market turbulence using the (XAU/USD) currency pair, capitalizing on the GoldWave Strategy for protection against volatility and economic downturns.": "Navigate market turbulence using the (XAU/USD) currency pair, capitalizing on the GoldWave Strategy for protection against volatility and economic downturns.",
  "Trend Following": "Trend Following",
  "Harness momentum through the GoldWave Strategy by tracking prevailing (XAU/USD) price trends, maximizing gains during upward movements.": "Harness momentum through the GoldWave Strategy by tracking prevailing (XAU/USD) price trends, maximizing gains during upward movements.",
  "Fundamental Analysis": "Fundamental Analysis",
  "Predict shifts in both gold and currency (XAU/USD) prices with the GoldWave Strategy, leveraging economic factors for a competitive advantage.": "Predict shifts in both gold and currency (XAU/USD) prices with the GoldWave Strategy, leveraging economic factors for a competitive advantage.",
  "Range Trading": "Range Trading",
  "Utilize the GoldWave Strategy to profit from (XAU/USD) price oscillations in sideways markets, identifying predictable ranges for consistent gains.": "Utilize the GoldWave Strategy to profit from (XAU/USD) price oscillations in sideways markets, identifying predictable ranges for consistent gains.",
  "Advantages of Gold Trading": "Advantages of Gold Trading",
  "Market Liquidity": "Market Liquidity",
  "Enjoy easy entry and exit with high trading volume, ensuring confidence in your trades.": "Enjoy easy entry and exit with high trading volume, ensuring confidence in your trades.",
  "Global Influence": "Global Influence",
  "Leverage gold's responsiveness to global economic trends for diverse opportunities.": "Leverage gold's responsiveness to global economic trends for diverse opportunities.",
  "Diverse Instruments": "Diverse Instruments",
  "Choose from a variety of trading options, tailored to your strategy and risk appetite": "Choose from a variety of trading options, tailored to your strategy and risk appetite",
  "Historical Stability": "Historical Stability",
  "Tap into centuries of wealth preservation, benefiting from gold's trusted stability.": "Tap into centuries of wealth preservation, benefiting from gold's trusted stability.",
  "Central Bank Impact": "Central Bank Impact",
  "Seize opportunities driven by central bank activities, guided by our expert insights.": "Seize opportunities driven by central bank activities, guided by our expert insights.",
  "AT QUANTBRIDGE,": "AT QUANTBRIDGE,",
  "We're committed to your gold trading success. Our strategies empower both seasoned traders and newcomers, enhancing your journey towards unlocking gold trading's potential.": "We're committed to your gold trading success. Our strategies empower both seasoned traders and newcomers, enhancing your journey towards unlocking gold trading's potential.",
  "Start Now": "Start Now",

  //market-diversity
  "Your Passage to Profound Market Diversity": "Your Passage to Profound Market Diversity",
  "Dive into Diverse Markets": 'Dive into Diverse Markets',
  "QuantBridge isn't limited to gold alone. We excel in a multitude of markets, enriching your investment opportunities like never before:": "QuantBridge isn't limited to gold alone. We excel in a multitude of markets, enriching your investment opportunities like never before:",
  "Forex": "Forex",
  "Harness the global currency landscape with QuantBridge's data-driven insights.": "Harness the global currency landscape with QuantBridge's data-driven insights.",
  "Crude Oil": "Crude Oil",
  "Seize rapid opportunities in the influential crude oil market.": "Seize rapid opportunities in the influential crude oil market.",
  "Metals": 'Metals',
  "From precious to industrial metals, benefit from our strategic trading solutions.": "From precious to industrial metals, benefit from our strategic trading solutions.",
  "Indices": "Indices",
  "Experience diversified sectors through index trading.": "Experience diversified sectors through index trading.",
  "QuantBridge expands your trading panorama, letting you craft a comprehensive investment strategy. Embark on a journey beyond gold with QuantBridge.": "QuantBridge expands your trading panorama, letting you craft a comprehensive investment strategy. Embark on a journey beyond gold with QuantBridge.",


  //resource
  "Elevate Your Trading with MT5": "Elevate Your Trading with MT5",
  "Embrace the power of MT5 by QuantBridge for advanced tools, automated strategies, mobile access, and multi-asset diversity. Anticipate MT5 listing, ensuring legitimacy. Elevate your trading journey today.": "Embrace the power of MT5 by QuantBridge for advanced tools, automated strategies, mobile access, and multi-asset diversity. Anticipate MT5 listing, ensuring legitimacy. Elevate your trading journey today.",
  "Start Trading": 'Start Trading',
  "Advanced, Mobile, and Legitimate": "Advanced, Mobile, and Legitimate",
  "Legitimacy Guaranteed": "Legitimacy Guaranteed",
  "With MT5 listing approaching, ensure authenticity and prevent fake transactions.": "With MT5 listing approaching, ensure authenticity and prevent fake transactions.",
  "Diverse Instruments": "Diverse Instruments",
  "Explore multi-asset trading possibilities beyond forex.": "Explore multi-asset trading possibilities beyond forex.",
  "Sophisticated Analysis": "Sophisticated Analysis",
  "Utilize advanced charting tools for precise market analysis.": "Utilize advanced charting tools for precise market analysis.",
  "Mobile Trading": "Mobile Trading",
  "Seamlessly trade on-the-go using the MT5 mobile app.": "Seamlessly trade on-the-go using the MT5 mobile app.",
  "Effortless Strategy": "Effortless Strategy",
  "Implement strategies effortlessly with automated trading via Expert Advisors.": "Implement strategies effortlessly with automated trading via Expert Advisors.",

  //trading-view
  "Online Trading Platform": "Online Trading Platform",
  "TradingView enables advanced trading analysis with user-friendly tools to visualize and analyze market trends, elevating your strategies. QuantBridge provides TradingView tools for even more precise insights, enhancing your trading potential.": "TradingView enables advanced trading analysis with user-friendly tools to visualize and analyze market trends, elevating your strategies. QuantBridge provides TradingView tools for even more precise insights, enhancing your trading potential.",
  "Advanced Analysis: Utilize TradingView's indicators and patterns seamlessly through QuantBridge for well-informed decisions.": "Advanced Analysis: Utilize TradingView's indicators and patterns seamlessly through QuantBridge for well-informed decisions.",
  "Real-Time Precision: TradingView ensures accurate real-time data, enabling swift responses to market shifts.": "Real-Time Precision: TradingView ensures accurate real-time data, enabling swift responses to market shifts.",

  //how-to-invest
  "Set Financial Goals": "Set Financial Goals",
  "Create an Account": "Create an Account",
  "Register with QuantBridge and complete verification": "Register with QuantBridge and complete verification",
  "Start Your MT5 Account": "Start Your MT5 Account",
  "Access your MT5 account provided by QuantBridge.": "Access your MT5 account provided by QuantBridge.",
  "Copy Trade": "Copy Trade",
  "Utilize Copy Trading feature to replicate experienced traders moves.": "Utilize Copy Trading feature to replicate experienced traders moves.",
  "Trade Management": "Trade Management",
  "Learn trade management tools in MT5, set stop-loss, and adjust sizes.": "Learn trade management tools in MT5, set stop-loss, and adjust sizes.",
  "By following these steps, you can begin your investment journey with confidence, leveraging the power of MT5 and our expert strategies to achieve your financial goals.": "By following these steps, you can begin your investment journey with confidence, leveraging the power of MT5 and our expert strategies to achieve your financial goals.",

  //our-team
  "United by Vision, Driven by Excellence": "United by Vision, Driven by Excellence",
  "INVESTING IN THE": "INVESTING IN THE",
  "RIGHT TEAM": "RIGHT TEAM",
  "We believe in the power of our exceptional team. Led by accomplished professionals from Switzerland, Netherlands, Spain, Singapore, and Canada, our collective resume boasts impressive directorial positions in prominent banks and successful investment departments.": "We believe in the power of our exceptional team. Led by accomplished professionals from Switzerland, Netherlands, Spain, Singapore, and Canada, our collective resume boasts impressive directorial positions in prominent banks and successful investment departments.",
  "We carefully select highly experienced and qualified traders, prioritizing profitability, capability, and unwavering dedication to growing our client's wealth. Rigorous vetting ensures that client funds are managed with the utmost care and skill. Our traders excel in handling significant capital, capitalizing on market opportunities, adeptly managing risks, and consistently delivering superior returns.": "We carefully select highly experienced and qualified traders, prioritizing profitability, capability, and unwavering dedication to growing our client's wealth. Rigorous vetting ensures that client funds are managed with the utmost care and skill. Our traders excel in handling significant capital, capitalizing on market opportunities, adeptly managing risks, and consistently delivering superior returns.",
  "Integrity and personal responsibility are core values at Quantbridge, and we take our investors' trust seriously. Your capital is treated with the utmost care as if it were our own, and we maintain total transparency in handling your money. Expect exceptional customer service and personalized attention, keeping you informed about your investments every step of the way.": "Integrity and personal responsibility are core values at Quantbridge, and we take our investors' trust seriously. Your capital is treated with the utmost care as if it were our own, and we maintain total transparency in handling your money. Expect exceptional customer service and personalized attention, keeping you informed about your investments every step of the way.",
  "Our Philosophy": "Our Philosophy",
  "At Quantbridge, we focus on generating steady returns over the medium to long term, irrespective of market conditions. Our adaptability and patience allow us to stay ahead in ever-changing markets. We develop new investment strategies to suit any environment, providing access to specialized markets with promising rewards. Patience is our virtue, minimizing risks and maximizing returns for our valued clients. Trust in Quantbridge for a rewarding investment journey.": "At Quantbridge, we focus on generating steady returns over the medium to long term, irrespective of market conditions. Our adaptability and patience allow us to stay ahead in ever-changing markets. We develop new investment strategies to suit any environment, providing access to specialized markets with promising rewards. Patience is our virtue, minimizing risks and maximizing returns for our valued clients. Trust in Quantbridge for a rewarding investment journey.",
  "CUSTOMIZED INVESTMENT APPROACH": "CUSTOMIZED INVESTMENT APPROACH",
  "At Quantbridge, we create tailored investment strategies to match your goals. Our systematic process combines fundamental research, quantitative analysis, and expert insights from our passionate team of traders. Your trust is our priority, and we are committed to delivering significant growth to your investments. Count on us to shape the system around your success.": "At Quantbridge, we create tailored investment strategies to match your goals. Our systematic process combines fundamental research, quantitative analysis, and expert insights from our passionate team of traders. Your trust is our priority, and we are committed to delivering significant growth to your investments. Count on us to shape the system around your success.",
  "OUR PROVEN": "OUR PROVEN",
  "INVESTMENT APPROACH": "INVESTMENT APPROACH",
  "Unlock Your Financial Imagination": "Unlock Your Financial Imagination",
  "FUNDAMENTAL ANALYSIS": "FUNDAMENTAL ANALYSIS",
  "Considering interest rates, production, earnings, and GDP to drive our investment decisions.": "Considering interest rates, production, earnings, and GDP to drive our investment decisions.",
  "QUANTITATIVE ANALYSIS": "QUANTITATIVE ANALYSIS",
  "Employing sophisticated algorithms to identify price-movement patterns and correlations between trading instruments.": "Employing sophisticated algorithms to identify price-movement patterns and correlations between trading instruments.",
  "OUR STRATEGIC": "OUR STRATEGIC",
  "TRADING APPROACH": "TRADING APPROACH",
  "At Quantbridge, our trading is purposeful and guided by specific constraints:": "At Quantbridge, our trading is purposeful and guided by specific constraints:",
  "Exceeding Benchmarks": "Exceeding Benchmarks",
  "Targeting total annualized net returns above the benchmark over any 3-year period, ensuring consistent performance.": "Targeting total annualized net returns above the benchmark over any 3-year period, ensuring consistent performance.",
  "Diverse Access": "Diverse Access",
  "We offer clients access to FX, futures, and commodities, expanding their investment horizons.": "We offer clients access to FX, futures, and commodities, expanding their investment horizons.",
  "Capital Preservation": "Capital Preservation",
  "Our priority is to preserve capital, with a maximum drawdown constraint of 20-30%, minimizing risks.": "Our priority is to preserve capital, with a maximum drawdown constraint of 20-30%, minimizing risks.",
  "Currency Hedging": "Currency Hedging",
  "Utilizing currency contracts to hedge against rate fluctuations, safeguarding your investments.": "Utilizing currency contracts to hedge against rate fluctuations, safeguarding your investments.",
  "ESSENTIAL RISK MANAGEMENT": "ESSENTIAL RISK MANAGEMENT",
  "At Quantbridge, risk management is paramount. It's a crucial part of our strategy, ensuring success in our trades and safeguarding our investors.": "At Quantbridge, risk management is paramount. It's a crucial part of our strategy, ensuring success in our trades and safeguarding our investors.",
  "SECURED RISK MANAGEMENT": "SECURED RISK MANAGEMENT",
  "Our dedicated team oversees risk management firm-wide. Emphasizing liquid markets, proprietary risk models, and a diversified funding structure to strengthen our approach. Your investments are safe with us.": "Our dedicated team oversees risk management firm-wide. Emphasizing liquid markets, proprietary risk models, and a diversified funding structure to strengthen our approach. Your investments are safe with us.",
  "ADVANCED RISK CONTROL": "ADVANCED RISK CONTROL",
  'CUTTING-EDGE RISK CONTROL': 'CUTTING-EDGE RISK CONTROL',
  'REAL-TIME POSITION UPDATES': 'REAL-TIME POSITION UPDATES',
  'MINIMAL SLIPPAGES FOR STOPS/ENTRY ORDERS': 'MINIMAL SLIPPAGES FOR STOPS/ENTRY ORDERS',
  'TRANSPARENCY THROUGH WEEKLY STATISTICS': 'TRANSPARENCY THROUGH WEEKLY STATISTICS',
  'CONTINUOUS RISK VS. MARKET PRICE AWARENESS': 'CONTINUOUS RISK VS. MARKET PRICE AWARENESS',
  'SECURE TRADING, EVEN DURING POWER OUTAGES': 'SECURE TRADING, EVEN DURING POWER OUTAGES',
  'LIGHTNING-FAST FILLS & LOW LATENCY': 'LIGHTNING-FAST FILLS & LOW LATENCY',
  'COMPETITIVE INTERBANK SPREADS': 'COMPETITIVE INTERBANK SPREADS',
  'At Quantbridge, diversify into 2 or more portfolios to minimize risk and maximize gains. Tailor your risk level to match your goals, time horizon, and tolerance for volatility, potentially improving returns. Trust in Quantbridge for a rewarding investment experience.': 'At Quantbridge, diversify into 2 or more portfolios to minimize risk and maximize gains. Tailor your risk level to match your goals, time horizon, and tolerance for volatility, potentially improving returns. Trust in Quantbridge for a rewarding investment experience.',
  "TRADE WITH CONFIDENCE AND FREEDOM": "TRADE WITH CONFIDENCE AND FREEDOM",

  //our-career
  "turning Dreams into Reality,": "turning Dreams into Reality,",
  "One Step at a Time.": "One Step at a Time.",
  "JOIN QUANTBRIDGE:": "JOIN QUANTBRIDGE:",
  "YOUR PATH TO SUCCESS": "YOUR PATH TO SUCCESS",
  "Calling all money magnets! At Quantbridge, you'll be part of an exceptional team, honing your trading skills and exploring cutting-edge technology. Working alongside financial market giants, our experienced leaders will mentor you, encouraging your growth and impact in the industry. Embrace this thrilling opportunity to thrive and succeed with Quantbridge. Your future awaits!": "Calling all money magnets! At Quantbridge, you'll be part of an exceptional team, honing your trading skills and exploring cutting-edge technology. Working alongside financial market giants, our experienced leaders will mentor you, encouraging your growth and impact in the industry. Embrace this thrilling opportunity to thrive and succeed with Quantbridge. Your future awaits!",
  "Where Your Career Thrives": "Where Your Career Thrives",
  "At Quantbridge, we heavily invest in our staff, fostering an atmosphere of openness, collaboration, focus, and shared commitment. We empower you with opportunities and responsibilities, encouraging independent and innovative thinking. Our adherence to strict values and principles underscores our unwavering commitment to our investors.": "At Quantbridge, we heavily invest in our staff, fostering an atmosphere of openness, collaboration, focus, and shared commitment. We empower you with opportunities and responsibilities, encouraging independent and innovative thinking. Our adherence to strict values and principles underscores our unwavering commitment to our investors.",
  "As part of Quantbridge, you can anticipate an exhilarating career and rewarding experiences.": "As part of Quantbridge, you can anticipate an exhilarating career and rewarding experiences.",
  "Send your CV to our HR Department at": "Send your CV to our HR Department at",
  "‘email address’": "‘email address’",
  "and embark on your journey with us. Your future awaits!": "and embark on your journey with us. Your future awaits!",
  "QUANTBRIDGE AFFILIATE PROGRAM:": "QUANTBRIDGE AFFILIATE PROGRAM:",
  "GREAT MINDS, GREAT REWARD": "GREAT MINDS, GREAT REWARD",
  "As a rapidly growing fund management service provider, Quantbridge is delighted to offer our existing investors an exclusive opportunity through our Quantbridge Affiliate Program. We believe in creating win-win situations. It's easy - just refer friends and family who are as savvy as you, and when they avail of Quantbridge services, you'll unlock lucrative rewards! Join the program and reap the benefits of our shared success.": "As a rapidly growing fund management service provider, Quantbridge is delighted to offer our existing investors an exclusive opportunity through our Quantbridge Affiliate Program. We believe in creating win-win situations. It's easy - just refer friends and family who are as savvy as you, and when they avail of Quantbridge services, you'll unlock lucrative rewards! Join the program and reap the benefits of our shared success.",
  "Earn Commissions for Referrals": "Earn Commissions for Referrals",
  "As a valued investor with Quantbridge, you have the opportunity to earn incentive payments by referring other potential investors to us. Whether it's friends or family, anyone interested can be referred. The more referrals you make, the greater your commissions. It's our way of expressing gratitude for your trust in us.": "As a valued investor with Quantbridge, you have the opportunity to earn incentive payments by referring other potential investors to us. Whether it's friends or family, anyone interested can be referred. The more referrals you make, the greater your commissions. It's our way of expressing gratitude for your trust in us.",
  "To join our affiliate program, simply sign up through our user-friendly online portal.": "To join our affiliate program, simply sign up through our user-friendly online portal.",
  "For more information or assistance, please don't hesitate to contact us. Your referrals make a significant impact, and we appreciate your continued support!": "For more information or assistance, please don't hesitate to contact us. Your referrals make a significant impact, and we appreciate your continued support!",
  "Join Us": "Join Us",

  //contact-us
  "Connecting with Us": "Connecting with Us",
  "Your Pathway to Solutions": "Your Pathway to Solutions",
  "Send Us a message": "Send Us a message",
  "Your Name": "Your Name",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "Email": "Email",
  "Phone Number": "Phone Number",
  "Country": "Country",
  "Please Choose A Country": "Please Choose A Country",
  "Company Name": "Company Name",
  "Department": "Department",
  "Inquiry Tools": "Inquiry Tools",
  "Your Subject": "Your Subject",
  "Message": "Message",
  "Your Message":  "Your Message",
  "Send": "Send",
  "General Inquiry": "General Inquiry",
  "Human Resources": "Human Resources",
  "Investor Relations": "Investor Relations",
  "Malaysia": "Malaysia",
  "Singapore": "Singapore",
  "This field is required.": "This field is required.",
}

export default EN;