import React from "react";
import { Col, Row } from "reactstrap";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const CustomizedApproach = (props) => {

    return (
        <>
            <div className="position-relative" id="customizedApproach">
                <Row className="justify-content-center align-items-center mx-0">
                    <Col lg={10} md={11} sm={12} xs={12}>
                        <div className="text-left">
                            <Row className="justify-content-center justify-content-md-start align-items-center mx-0">
                                <Col lg={7} md={11} sm={11} xs={11} className="px-0">
                                    <span className="sectionTitle d-block text-left">
                                        {translate(props.lang, "CUSTOMIZED INVESTMENT APPROACH")}
                                    </span>
                                    <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4" />
                                </Col>
                            </Row>

                            <Row className="justify-content-start align-items-end mx-0 customizedApproachBox my-5">
                                <Col lg={6} md={12} sm={12} xs={12} className="">
                                    <span className="sectionDesc d-block text-justify">
                                        {translate(props.lang, "At Quantbridge, we create tailored investment strategies to match your goals. Our systematic process combines fundamental research, quantitative analysis, and expert insights from our passionate team of traders. Your trust is our priority, and we are committed to delivering significant growth to your investments. Count on us to shape the system around your success.")}
                                    </span>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="px-5 position-relative">
                                    <img src={require("../../assets/img/ourTeam/customizedApproachImg.png").default} alt="" className="customizedApproachImg" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <img src={require("../../assets/img/ourTeam/customizedApproachDeco.png").default} alt="" className="customizedApproachDeco" />
            </div>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(CustomizedApproach);