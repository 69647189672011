import React from "react";
import { Col, Row } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import certificate1 from "../../assets/img/our-partner/certificate1.png";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const certificateArray = [
    { img: certificate1 }, { img: certificate1 }, { img: certificate1 }, { img: certificate1 },
]

const Certificate = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        lazyLoad: "ondemand",
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 992 ? 3 : 1,
        swipe: true,
        centerMode: true,
        centerPadding: 0,
    };

    return (
        <>
            <Row className="justify-content-center align-items-center mx-0 position-relative" id="certificate">
                <Col lg={11} md={11} sm={12} xs={12} className="">

                    <Row className="justify-content-center align-items-center mx-0 position-relative pt-5">
                        <Col lg={6} md={11} sm={12} xs={12} className="">
                            <div className="text-center">
                                <span className="sectionTitle d-block">
                                    {translate(props.lang, "Bost Technology Limited CERTIFICATE")}
                                </span>
                                <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="mt-3" />
                            </div>
                        </Col>
                    </Row>

                    <div className="text-center mt-5 mt-lg-4 position-relative">
                        <Slider {...settings} >
                            {
                                certificateArray.map((item, i) => (
                                    <div className="px-5 px-lg-0" key={i}>
                                        <img src={item.img} className="d-block mx-auto" />
                                    </div>

                                ))
                            }
                        </Slider>

                        <div className="certificateShadow">
                            <img src={require("../../assets/img/our-partner/certificateShadow.png").default} alt="" className="mt-3" />
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Certificate);