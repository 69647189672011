import React from "react";
import { Col, Row } from "reactstrap";
import Fade from "react-reveal/Fade";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const TradingPlatform = (props) => {

    return (
        <>
            <Row className="justify-content-center align-items-center mx-0 position-relative" id="tradingPlatform">
                <Col lg={5} md={8} sm={11} xs={11} className="mb-4">
                    <img src={require("../../assets/img/trading-view/tradingPlatformImg.png").default} alt="mission" className="w-100" />
                </Col>
                <Col lg={5} md={11} sm={12} xs={12} className="">
                    <Fade left duration={1500}>
                        <div className="text-center text-lg-left">
                            <span className="sectionTitle d-block text-center text-lg-left">
                                {translate(props.lang, "Online Trading Platform")}
                            </span>
                            <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="mt-3" />
                        </div>
                        <Row className="justify-content-start align-items-start mx-0 position-relative mt-5">

                            <Col lg={12} md={12} sm={12} xs={12} className="mb-5">
                                <span className="sectionDesc text-justify d-block">
                                    {translate(props.lang, "TradingView enables advanced trading analysis with user-friendly tools to visualize and analyze market trends, elevating your strategies. QuantBridge provides TradingView tools for even more precise insights, enhancing your trading potential.")}
                                </span>
                            </Col>

                            <Col lg={2} md={1} sm={2} xs={2} className="">
                                <div className="missionIndexText text-center">
                                    <img src={require("../../assets/img/trading-view/tradingPlatformSymbol.png").default} alt="" className="mx-auto tradingPlatformSymbol" />
                                </div>
                            </Col>
                            <Col lg={10} md={11} sm={10} xs={10} className="pl-0 mb-4">
                                <span className="sectionDesc text-justify d-block">
                                    {translate(props.lang, "Advanced Analysis: Utilize TradingView's indicators and patterns seamlessly through QuantBridge for well-informed decisions.")}
                                </span>
                            </Col>

                            <Col lg={2} md={1} sm={2} xs={2} className="">
                                <div className="missionIndexText text-center">
                                    <img src={require("../../assets/img/trading-view/tradingPlatformSymbol.png").default} alt="" className="mx-auto tradingPlatformSymbol" />
                                </div>
                            </Col>
                            <Col lg={10} md={11} sm={10} xs={10} className="pl-0 mb-4">
                                <span className="sectionDesc text-justify d-block">
                                    {translate(props.lang, "Real-Time Precision: TradingView ensures accurate real-time data, enabling swift responses to market shifts.")}
                                </span>
                            </Col>
                        </Row>
                    </Fade>
                </Col>

                <img src={require("../../assets/img/trading-view/tradingPlatformDeco.png").default} alt="tradingPlatformDeco" className="tradingPlatformDeco d-none d-lg-block" />
            </Row>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(TradingPlatform);
