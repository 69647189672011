import React from "react";
import { Col, Row } from "reactstrap";
import Marquee from "react-fast-marquee";
import Zoom from "react-reveal/Zoom";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const ProvenApproach = (props) => {

    return (
        <>
            <div className="" id="provenApproach">
                <Row className="justify-content-center align-items-center mx-0">
                    <Col lg={10} md={11} sm={12} xs={12}>
                        <div className="text-left">
                            <Row className="justify-content-center align-items-center mx-0">
                                <Col lg={7} md={12} sm={12} xs={12} className="text-center">
                                    <span className="sectionTitle d-block text-center">
                                        {translate(props.lang, "OUR PROVEN")}
                                    </span>
                                    <span className="sectionTitle d-block text-center">
                                        {translate(props.lang, "INVESTMENT APPROACH")}
                                    </span>
                                    <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4 mx-auto text-center" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <div className="my-5 py-5">
                    <Marquee>
                        <div className="mx-3">
                            <span className="marqueeHugeText d-block">{translate(props.lang, "Unlock Your Financial Imagination")}</span>
                        </div>
                        <div className="mx-3">
                            <span className="marqueeHugeText d-block">{translate(props.lang, "Unlock Your Financial Imagination")}</span>
                        </div>
                    </Marquee>
                </div>
            </div>

            <Row className="justify-content-center align-items-center mx-0" id="analysisDiv">
                <Col lg={10} md={11} sm={12} xs={12}>
                    <Row className="justify-content-between align-items-start mx-0" id="analysisDiv">
                        <Col lg={5} md={12} sm={12} xs={12} className="mb-5">
                            <Zoom duration={1500}>
                                <div className="analysisBox ">
                                    <span className="analysisTitle d-block mb-4 mt-4">
                                        {translate(props.lang, "FUNDAMENTAL ANALYSIS")}
                                    </span>
                                    <span className="sectionDesc d-block text-left mb-5">
                                        {translate(props.lang, "Considering interest rates, production, earnings, and GDP to drive our investment decisions.")}
                                    </span>
                                </div>
                            </Zoom>
                        </Col>
                        <Col lg={5} md={12} sm={12} xs={12} className="mb-5">
                            <Zoom duration={1500}>
                                <div className="analysisBox">
                                    <span className="analysisTitle d-block mb-4 mt-4">
                                        {translate(props.lang, "QUANTITATIVE ANALYSIS")}
                                    </span>
                                    <span className="sectionDesc d-block text-left mb-4">
                                        {translate(props.lang, "Employing sophisticated algorithms to identify price-movement patterns and correlations between trading instruments.")}
                                    </span>
                                </div>
                            </Zoom>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(ProvenApproach);