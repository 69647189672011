import React from "react";
import { Col, Row } from "reactstrap";
import Fade from "react-reveal/Fade";
import { TickerTape } from "react-ts-tradingview-widgets";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const HomeHeader = (props) => {
    console.log("PROPS", props.lang)
    return (
        <>
            <div id="homeHeader">
                <Row className="justify-content-center justify-content-lg-start align-items-center mx-0 homeHeaderSection">
                    <Col lg={6} md={11} sm={11} xs={11} className="headerPadding">
                        <div className="text-center text-lg-left">
                            <Fade duration={1500}>
                                <span className="headerTitle">
                                    {translate(props.lang, "Welcome To Quantbridge Capital")}
                                </span>
                            </Fade>
                        </div>
                    </Col>
                </Row>

                <div className="marquee-header" id="marqueeHeader">
                    <TickerTape colorTheme="dark" isTransparent={true} displayMode="compact"></TickerTape>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(HomeHeader);
