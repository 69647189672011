import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { FaTelegramPlane } from 'react-icons/fa';
import Bounce from "react-reveal/Bounce";
import { connect } from 'react-redux';
import { translate } from 'utils/translate';

const SubscribeNewsletter = (props) => {
    const [email, setEmail] = useState('');

    return (
        <>
            <Row className="justify-content-center align-items-center mx-0 position-relative" id="subscribeNewsletter">
                <Col lg={5} md={9} sm={12} xs={12} className="">
                    <div className="text-center position-relative">
                        <Bounce duration={1500}>
                            <img src={require("../../assets/img/home/newletterImgBg.png").default} alt="" className="newletterImgBg" />
                            <img src={require("../../assets/img/home/newletterImg.png").default} alt="" className="w-100" />
                        </Bounce>
                    </div>
                </Col>
                <Col lg={6} md={10} sm={12} xs={12} className="text-center text-lg-left position-relative">
                    <div className="text-center text-lg-left">
                        <span className="sectionTitle d-block text-center text-lg-left">
                            {translate(props.lang, "Subscribe")}
                        </span>
                        <span className="sectionTitle d-block text-center text-lg-left">
                            {translate(props.lang, "Newsletter")}
                        </span>
                    </div>

                    <img src={require("../../assets/img/home/ourClientBorder.jpg").default} alt="" className="my-4" />

                    <span className="sectionDesc d-block text-center text-lg-left">
                        {translate(props.lang, "You will never miss our latest news etc.")}
                    </span>

                    <div className="d-flex justify-content-start aligm-items-center mt-5 pt-5">
                        <input className="form-control border-none input-transparent"
                            type="text"
                            name="newsletter"
                            placeholder="user@email.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <a href={"mailto:support@quantbridge.io?subject=Quantbridge Website Newsletter Subscribe&body=Email: " + email}>
                            <div className="newsletterBtn py-3 px-5">
                                <FaTelegramPlane className="newsletterBtnText" />
                            </div>
                        </a>
                    </div>

                    <span className="smallText d-block text-center text-lg-left mt-3">
                        {translate(props.lang, "We promise not to spam you!")}
                    </span>
                </Col>
                <img src={require("../../assets/img/home/newsletterSideImg.png").default} alt="" className="newsletterSideImgPos d-none d-lg-block" />
            </Row>
        </>
    );
};

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(SubscribeNewsletter);